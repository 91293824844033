import * as React from "react";
import {useState} from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import {SwipeableOptionsProps} from "../types";
import {Radio, RadioGroup} from "@mui/joy";
import {useSwipeable} from "react-swipeable";

const Screen13: React.FC<SwipeableOptionsProps> = ({optionsPromise}) => {
    const [selectedValue, setSelectedValue] = useState<string>('');
    const isComponentReady = selectedValue !== ''
    const swipeableOptions = optionsPromise(
        isComponentReady,
        (profile) => profile.riskManagement = {
            ...profile.riskManagement,
            overconfidenceTolerance: selectedValue
        }
    )

    return (
        <Stack
            {...useSwipeable((swipeableOptions))}
            direction="column"
            justifyContent="space-around"
        >
            <Stack direction="column" gap={7}>
                <Typography level="h3" textAlign="center">
                    When you're winning a bunch of trades, what are you doing?
                </Typography>
                <RadioGroup
                    name="radio-buttons-group"
                    sx={{
                        gap: 1
                    }}
                    onChange={(event) => setSelectedValue(event.target.value)}
                >
                    <Radio
                        value="High"
                        label="I continue to stick to my plan"
                    />
                    <Radio
                        value="Medium"
                        label="I trade the same but with more confidence"
                    />
                    <Radio
                        value="Low"
                        label="I start risking more"
                    />
                    <Radio
                        value="Very high"
                        label="I get cautious and reduce my trading activity"
                    />
                </RadioGroup>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                gap={2}
            >
                <Button
                    variant="outlined"
                    sx={{
                        width: 80
                    }}
                    onClick={swipeableOptions.onSwipedRight}
                >
                    Back
                </Button>
                <Button
                    sx={{
                        width: 80
                    }}
                    onClick={() => {
                        swipeableOptions.onSwipedLeft();
                    }}
                    disabled={!isComponentReady}
                >
                    Next
                </Button>
            </Stack>
        </Stack>
    );
}

export default Screen13;