
export const ROUTES = {

    ROOT: '/',
    DASHBOARD: '/dashboard',
    CHAT: '/chat',
    PROFILE: '/profile',
    JOURNAL: '/journal',
    TRADING_PLAN: '/trading-plan',
    PRACTICES: '/practices',
    SUPPORT: '/support',
    SETTINGS: '/settings',
    PAYMENT: '/payment'
}