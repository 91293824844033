import React, {useState} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Box, Typography} from "@mui/joy";
import Divider from "@mui/joy/Divider";
import Card from "@mui/joy/Card";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";

const PaymentForm: React.FC<{ onSuccess: () => void; onError: () => void }> = ({
                                                                                   onSuccess,
                                                                                   onError
                                                                               }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) return;

        setIsProcessing(true);

        try {
            const {error} = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/payment-complete`,
                },
                redirect: 'if_required'
            });

            if (error) {
                onError();
            } else {
                onSuccess();
            }
        } catch (e) {
            onError();
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <Card sx={{
            flex: 1,
            width: '50%',
            maxWidth: '600px',
            alignSelf: 'center',
            mx: '1em'
        }}>
            <Box sx={{mb: 1}}>
                <Typography level="title-md">Payment details</Typography>
            </Box>
            <Divider/>
            <form onSubmit={handleSubmit}>
                <Stack justifyContent="flex-end" spacing={2}>
                    <PaymentElement/>
                    <Button
                        disabled={!stripe || isProcessing}
                        size="md"
                        color="primary"
                        type="submit"
                        sx={{
                            alignSelf: 'end',
                            borderRadius: 'sm',
                            mx: '5px'
                        }}
                    >
                        {isProcessing ? 'Processing...' : 'Pay now'}
                    </Button>
                </Stack>
            </form>
        </Card>

    );
};

export default PaymentForm;