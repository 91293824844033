import * as React from "react";
import {useEffect} from "react";
import Stack from "@mui/joy/Stack";
import MessageBubble from "./MessageBubble";
import Box from "@mui/joy/Box";
import {TextStreamStartedResponse} from "../../../../../api/responseTypes";
import {WEBSOCKET_RECEIVE_EVENTS} from "../../../../../constants/api";
import {useSocket} from "../../../../components/context/SocketContext";
import {parseTimestampString} from "../../../../../util/utils";
import {welcomeMessage} from "../../../const";
import {useChatHistory} from "../../../../components/context/ChatHistoryContext";
import {AssistantMessage, Author} from "../../../../types";

const ChatBody: React.FC = () => {
    const socket = useSocket();
    const {chatHistory, setChatHistory} = useChatHistory();
    const messages = [welcomeMessage, ...chatHistory.messages];

    useEffect(() => {
        const text_stream_started_listener = (response: string) => {
            const resp: TextStreamStartedResponse = JSON.parse(response);
            let received = new AssistantMessage('', parseTimestampString(resp.timestamp));
            setChatHistory((chatHistory) => ({
                messages: [...chatHistory.messages, received]
            }));
            // TODO: lock "send" button
        };
        const text_stream_chunk_listener = (chunk: string) => {
            setChatHistory((chatHistory) => {
                const newMessages = [...chatHistory.messages];
                for (let i = newMessages.length - 1; i >= 0; i--) {
                    if (newMessages[i].author === Author.ASSISTANT) {
                        newMessages[i].message += chunk
                        break
                    }
                }
                return {
                    messages: newMessages
                };
            });
        };
        const text_stream_stop_listener = () => {
            // TODO: unlock "send" button
        };
        const text_stream_error_listener = () => {
            // TODO: unlock "send" button
            console.log("Text stream error happened")
        };

        if (socket) {
            socket.on(WEBSOCKET_RECEIVE_EVENTS.TEXT_STREAM_STARTED, text_stream_started_listener);
            socket.on(WEBSOCKET_RECEIVE_EVENTS.TEXT_STREAM_CHUNK, text_stream_chunk_listener);
            socket.on(WEBSOCKET_RECEIVE_EVENTS.TEXT_STREAM_STOP, text_stream_stop_listener);
            socket.on(WEBSOCKET_RECEIVE_EVENTS.TEXT_STREAM_ERROR, text_stream_error_listener);
        }

        return () => {
            if (socket) {
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.TEXT_STREAM_STARTED);
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.TEXT_STREAM_CHUNK);
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.TEXT_STREAM_STOP);
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.TEXT_STREAM_ERROR);
            }
        };
    }, [socket]);

    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                minHeight: 0,
                px: 2,
                py: 3,
                overflowY: 'scroll',
                flexDirection: 'column-reverse',
            }}
        >
            <Stack spacing={2} justifyContent="flex-end">
                {messages.map((message, index: number) => {
                    const isUserMessage = message.author === Author.USER;
                    return (
                        <Stack
                            key={index}
                            direction="row"
                            spacing={2}
                            flexDirection={isUserMessage ? 'row-reverse' : 'row'}
                        >
                            <MessageBubble imessage={message} isLast={index === messages.length - 1} />
                        </Stack>
                    );
                })}
            </Stack>
        </Box>
    );
}

export default ChatBody;