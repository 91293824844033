import * as React from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import FormControl, {FormControlProps} from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';

const LanguageSelector: React.FC<FormControlProps> = (props) => {
    const { sx, ...other } = props;
    return (
        <FormControl
            {...other}
            sx={[{ display: { sm: 'contents' } }, ...(Array.isArray(sx) ? sx : [sx])]}
        >
            <FormLabel>Language</FormLabel>
            <Autocomplete
                size="sm"
                autoHighlight
                isOptionEqualToValue={(option, value) => option.code === value.code}
                defaultValue={{ code: 'en-EN', label: 'English' }}
                options={[{ code: 'en-EN', label: 'English' }]}
                renderOption={(optionProps, option) => (
                    <AutocompleteOption {...optionProps}>
                        {/*<ListItemDecorator>*/}
                            {/*<AspectRatio ratio="1" sx={{ minWidth: 20, borderRadius: '50%' }}>*/}
                            {/*    <img*/}
                            {/*        loading="lazy"*/}
                            {/*        width="20"*/}
                            {/*        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}*/}
                            {/*        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}*/}
                                    {/*alt=""*/}
                                {/*/>*/}
                            {/*</AspectRatio>*/}
                        {/*</ListItemDecorator>*/}
                        {option.label}
                    </AutocompleteOption>
                )}
                slotProps={{
                    input: {
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }
                }}
            />
        </FormControl>
    );
}

export default LanguageSelector;