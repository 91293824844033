import * as React from "react";
import App from "../app/App";
import Login from "./Login";
import {useAuth} from "../components/context/AuthContext";


const Auth: React.FC = () => {
    const {isAuthenticated} = useAuth();

    return (
        <>
            {isAuthenticated ? <App/> : <Login/>}
        </>
    );
}

export default Auth;