import * as React from "react";
import Box from "@mui/joy/Box";
import {QuizScreensProps, SwipeableOptions, TradingProfile} from "./types";
import {useUserSettings} from "../../components/context/UserSettingsContext";
import {useUserTradingProfile} from "../../components/context/UserTradingProfileContext";

const QuizScreens: React.FC<QuizScreensProps> = ({
                                                     screens,
                                                     currentStep,
                                                     setCurrentStep,
                                                     tradingProfile
                                                 }) => {
    const {setUserSettings} = useUserSettings();
    const {setUserProfile} = useUserTradingProfile();
    const currentScreenTransition = '0%'
    const xsTransition = '250'
    const smTransition = '300'
    const mdTransition = '350'
    const handleNext = () => {
        if (currentStep < screens.length - 1) {
            setCurrentStep(currentStep + 1);
        } else if (currentStep === screens.length - 1) {
            setUserProfile(tradingProfile)
            setUserSettings(prev => {
                return {
                    ...prev,
                    quiz_finished: true
                }
            })
        }
    };
    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };
    const swipeableOptionsPromise = (isScreenCompleted: boolean,
                                     processProfile: (profile: TradingProfile) => void): SwipeableOptions => ({
        onSwipedLeft: () => {
            if (isScreenCompleted) {
                processProfile(tradingProfile)
                handleNext();
            }
        },
        onSwipedRight: () => {
            handlePrevious();
        },
        trackTouch: true
    });

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                flexGrow: 1,
                overflow: 'hidden',
            }}
        >
            {
                screens.map((screen, index) => {
                    const transition = (transitionValue: string) => {
                        return currentStep > index
                            ? `-${transitionValue}%`
                            : currentStep < index
                                ? `${transitionValue}%`
                                : currentScreenTransition
                    }
                    return <Box
                        key={index}
                        sx={{
                            height: {xs: '75%', sm: '80%'},
                            display: 'flex',
                            maxWidth: '33em',
                            margin: '1em',
                            paddingX: '1em',
                            position: 'fixed',
                            transform: {
                                xs: `translate(${transition(xsTransition)})`,
                                sm: `translate(${transition(smTransition)})`,
                                md: `translate(${transition(mdTransition)})`,
                            },
                            transition: 'transform 0.7s',
                        }}
                    >
                        {React.createElement(screen, {
                            optionsPromise: swipeableOptionsPromise
                        })}
                    </Box>
                })
            }
        </Box>
    );
}

export default QuizScreens;