import React, {useEffect, useState} from "react";
import {Card, CardContent, Stack} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import CardActions from "@mui/joy/CardActions";

import {useSubscriptionTypes} from "./useSubscriptionTypes";
import DefaultHeader from "../../components/DefaultHeader";
import {APP_CONST, WEBSOCKET_EMIT_EVENTS, WEBSOCKET_RECEIVE_EVENTS} from "../../../constants/api";
import {useSocket} from "../../components/context/SocketContext";
import {SubscriptionType} from "./types";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants/routes";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(APP_CONST.STRIPE_PUBLIC_KEY);

const Payment = () => {
    const navigate = useNavigate();
    const socket = useSocket();
    const {subscriptionTypes} = useSubscriptionTypes()
    const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionType | null>(null);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);

    useEffect(() => {
        if (!socket) return;

        socket.on(WEBSOCKET_RECEIVE_EVENTS.SUBSCRIPTION_PAYMENT_INTENT_CREATED, (data) => {
            setClientSecret(data.client_secret);
            setPaymentIntentId(data.payment_intent_id);
        });

        socket.on(WEBSOCKET_RECEIVE_EVENTS.SUBSCRIPTION_PAYMENT_COMPLETED, () => {
            navigate(ROUTES.PROFILE);
        });

        socket.on(WEBSOCKET_RECEIVE_EVENTS.SUBSCRIPTION_PAYMENT_FAILED, () => {
            navigate(ROUTES.PAYMENT);
        });

        return () => {
            socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.SUBSCRIPTION_PAYMENT_INTENT_CREATED);
            socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.SUBSCRIPTION_PAYMENT_COMPLETED);
            socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.SUBSCRIPTION_PAYMENT_FAILED);
        };
    }, [socket]);

    const handleSubscriptionSelect = async (subscriptionType: SubscriptionType) => {
        if (socket) {
            setSelectedSubscription(subscriptionType);
            socket.emit(WEBSOCKET_EMIT_EVENTS.INIT_SUBSCRIPTION_PAYMENT, subscriptionType.id);
        }
    };

    const handlePaymentSuccess = () => {
        if (socket && selectedSubscription) {
            socket.emit(WEBSOCKET_EMIT_EVENTS.SUBSCRIPTION_PAYMENT_SUCCESS, {
                'payment_intent_id': paymentIntentId,
                'subscription_type_id': selectedSubscription.id
            });
        }
    };

    const handlePaymentError = () => {
        if (socket && selectedSubscription) {
            socket.emit(WEBSOCKET_EMIT_EVENTS.SUBSCRIPTION_PAYMENT_FAILURE, {
                'payment_intent_id': paymentIntentId,
                'subscription_type_id': selectedSubscription.id
            });
        }
    };

    return (
        <>
            <DefaultHeader title="Payment"/>
            <Stack flexDirection="row"
                   gap={5}
                   mt={5}
                   mb={5}
                   alignItems="center"
                   justifyContent="center"
                   flexWrap="wrap"
            >
                {!clientSecret
                    ? (
                        subscriptionTypes.map((subscriptionType) => (
                            <Card
                                sx={{width: 300, height: 300}}
                                key={subscriptionType.id}
                            >
                                <CardContent>
                                    <Box sx={{mb: 1}}>
                                        <Typography level="title-md">{subscriptionType.name}</Typography>
                                    </Box>
                                    <Divider/>
                                    <Box sx={{mb: 1}}>
                                        <Typography level="h1">{`${subscriptionType.price || 0}$`}</Typography>
                                    </Box>

                                    <Typography sx={{color: 'text.secondary'}}>
                                        {subscriptionType.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        onClick={() => handleSubscriptionSelect(subscriptionType)}
                                        disabled={!!selectedSubscription}
                                    >
                                        {selectedSubscription?.id === subscriptionType.id
                                            ? 'Processing...'
                                            : subscriptionType.type === 'trial'
                                                ? 'Activate for free'
                                                : 'Buy'}
                                    </Button>
                                </CardActions>
                            </Card>
                        ))
                    ) : (
                        <Elements stripe={stripePromise} options={{clientSecret}}>
                            <PaymentForm
                                onSuccess={handlePaymentSuccess}
                                onError={handlePaymentError}
                            />
                        </Elements>
                    )}
            </Stack>
        </>
    )
}

export default Payment;