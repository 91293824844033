import {AudioInputControls} from "../../types";
import React, {createContext, ReactNode, useContext, useRef} from 'react';

const AudioInputControlsContext = createContext({
    audioContext: null,
    workletNode: null,
    sourceNode: null,
    channelSplitterNode: null,
    channelMergerNode: null
} as AudioInputControls);

export const AudioInputControlsProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const controlsRef = useRef<AudioInputControls>({
        audioContext: null,
        workletNode: null,
        sourceNode: null,
        channelSplitterNode: null,
        channelMergerNode: null
    });

    return (
        <AudioInputControlsContext.Provider value={controlsRef.current}>
            {children}
        </AudioInputControlsContext.Provider>
    );
}

export const useAudioInputControls = () => {
    return useContext(AudioInputControlsContext);
};