import {useSubscription} from "../../components/context/SubscriptionContext";
import React from "react";
import {ROUTES} from "../../../constants/routes";
import {Navigate} from "react-router-dom";

interface SubscriptionValidatorProps {
    children: React.ReactElement;
}

const SubscriptionValidator: React.FC<SubscriptionValidatorProps> = ({children}) => {
    const {isValidSubscription} = useSubscription();

    if (!isValidSubscription) {
        return <Navigate to={ROUTES.PAYMENT} replace/>;
    }

    return children;
};

export default SubscriptionValidator;