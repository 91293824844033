import * as React from "react";
import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import IconButton from "@mui/joy/IconButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LanguageSelector from "./LanguageSelector";
import CardOverflow from "@mui/joy/CardOverflow";
import CardActions from "@mui/joy/CardActions";
import Button from "@mui/joy/Button";
import {useUserSettings} from "../../../../../components/context/UserSettingsContext";


const PersonalCard: React.FC = () => {
    const {userSettings} = useUserSettings();

    return (
        <Card sx={{flex: 1, width: '100%'}}>
            <Box sx={{mb: 1}}>
                <Typography level="title-md">Personal info</Typography>
                <Typography level="body-sm">
                    Customize your profile information.
                </Typography>
            </Box>
            <Divider/>
            <Stack
                direction="row"
                spacing={3}
                sx={{display: {xs: 'none', md: 'flex'}, my: 1}}
            >
                <Stack direction="column" spacing={1}>
                    <AspectRatio
                        ratio="1"
                        maxHeight={200}
                        sx={{flex: 1, minWidth: 120, borderRadius: '100%'}}
                    >
                        <Avatar
                            variant="outlined"
                            size="sm"
                            src={userSettings.picture}
                        />
                    </AspectRatio>
                    <IconButton
                        aria-label="upload new picture"
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        sx={{
                            bgcolor: 'background.cards',
                            position: 'absolute',
                            zIndex: 2,
                            borderRadius: '50%',
                            left: 100,
                            top: 170,
                            boxShadow: 'sm',
                        }}
                    >
                        <EditRoundedIcon/>
                    </IconButton>
                </Stack>
                <Stack spacing={2} sx={{flexGrow: 1}}>
                    <FormControl
                        sx={{display: {sm: 'flex-column', md: 'flex-row'}}}
                    >
                        <FormLabel>Name</FormLabel>
                        <Typography
                            level="body-sm"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 'sm',
                                p: 1,
                                pl: 1.5,
                                flexGrow: 1,
                                color: 'text.secondary',
                            }}
                        >
                            {userSettings.name || 'Your name'}
                        </Typography>
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                        <FormControl sx={{flexGrow: 1}}>
                            <FormLabel>Email</FormLabel>
                            {/*<Input*/}
                            {/*    size="sm"*/}
                            {/*    type="email"*/}
                            {/*    startDecorator={<EmailRoundedIcon/>}*/}
                            {/*    placeholder="Email"*/}
                            {/*    defaultValue={userSettings.email}*/}
                            {/*    sx={{flexGrow: 1}}*/}
                            {/*/>*/}
                            <Typography
                                level="body-sm"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    borderRadius: 'sm',
                                    p: 1,
                                    pl: 1.5,
                                    flexGrow: 1,
                                    color: 'text.secondary',
                                }}
                            >
                                <EmailRoundedIcon sx={{ mr: 1, fontSize: 'small' }} />
                                {userSettings.email || 'Email'}
                            </Typography>
                        </FormControl>
                    </Stack>
                    <div>
                        <LanguageSelector/>
                    </div>
                </Stack>
            </Stack>

            {/*TODO: remove duplication and leave only one form*/}
            <Stack
                direction="column"
                spacing={2}
                sx={{display: {xs: 'flex', md: 'none'}, my: 1}}
            >
                <Stack direction="row" spacing={2}>
                    <Stack direction="column" spacing={1}>
                        <AspectRatio
                            ratio="1"
                            maxHeight={108}
                            sx={{flex: 1, minWidth: 108, borderRadius: '100%'}}
                        >
                            <Avatar
                                variant="outlined"
                                size="sm"
                                src={userSettings.picture}
                            />
                        </AspectRatio>
                        <IconButton
                            aria-label="upload new picture"
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            sx={{
                                bgcolor: 'background.cards',
                                position: 'absolute',
                                zIndex: 2,
                                borderRadius: '50%',
                                left: 85,
                                top: 180,
                                boxShadow: 'sm',
                            }}
                        >
                            <EditRoundedIcon/>
                        </IconButton>
                    </Stack>
                    <Stack spacing={1} sx={{flexGrow: 1}}>
                        <FormLabel>Name</FormLabel>
                        <FormControl
                            sx={{
                                display: {
                                    sm: 'flex-column',
                                    md: 'flex-row',
                                },
                                gap: 2,
                            }}
                        >
                            <Typography
                                level="body-sm"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    borderRadius: 'sm',
                                    p: 1,
                                    pl: 1.5,
                                    flexGrow: 1,
                                    color: 'text.secondary',
                                }}
                            >
                                {userSettings.name || 'Your name'}
                            </Typography>
                        </FormControl>
                    </Stack>
                </Stack>
                <FormControl sx={{flexGrow: 1}}>
                    <FormLabel>Email</FormLabel>
                    <Typography
                        level="body-sm"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 'sm',
                            p: 1,
                            pl: 1.5,
                            flexGrow: 1,
                            color: 'text.secondary',
                        }}
                    >
                        <EmailRoundedIcon sx={{ mr: 1, fontSize: 'small' }} />
                        {userSettings.email || 'Email'}
                    </Typography>
                </FormControl>
                <div>
                    <LanguageSelector/>
                </div>
            </Stack>
            <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                    <Button size="sm" variant="outlined" color="neutral">
                        Cancel
                    </Button>
                    <Button size="sm" variant="solid" disabled>
                        Save
                    </Button>
                </CardActions>
            </CardOverflow>
        </Card>
    );
}

export default PersonalCard;