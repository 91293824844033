import React from "react";
import Chat from "../chat/Chat";
import Sidebar from "../sidebar/Sidebar";
import Box from "@mui/joy/Box";
import {ROUTES} from "../../../constants/routes";
import {HashRouter, Route, Routes} from "react-router-dom";
import UserProfile from "../profile/UserProfile";
import Sheet from "@mui/joy/Sheet";
import Practices from "../practices/Practices";
import Payment from "../payment/Payment";
import SubscriptionValidator from "../profile/SubscriptionValidator";

const AppRouter: React.FC = () => {
    const chatComponent = () => {
        return <SubscriptionValidator>
            <Chat/>
        </SubscriptionValidator>
    };

    return (
        <HashRouter>
            <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                <Sidebar/>
                <Box component="main" className="MainContent" sx={{flex: 1}}>
                    <Sheet
                        sx={{
                            height: '100dvh',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: 'background.level1',
                            overflowY: 'scroll'
                        }}
                    >
                        <Routes>
                            <Route path="/">
                                <Route index element={chatComponent()}/>
                                <Route path={ROUTES.CHAT} element={chatComponent()}/>
                                <Route path={ROUTES.PROFILE} element={<UserProfile/>}/>
                                <Route path={ROUTES.PRACTICES} element={<Practices/>}/>
                                <Route path={ROUTES.PAYMENT} element={<Payment/>}/>
                            </Route>
                        </Routes>
                    </Sheet>
                </Box>
            </Box>
        </HashRouter>
    );
}

export default AppRouter;