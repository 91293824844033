import * as React from 'react';
import {useEffect} from 'react';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {toggleSidebar} from '../../../sidebar/utils';
import {AgentProps} from "../../const";
import {Switch} from "@mui/joy";
import {disableVoiceResponse, enableVoiceResponse} from "../../audio-output-utils";
import {useSocket} from "../../../../components/context/SocketContext";
import {WEBSOCKET_EMIT_EVENTS} from "../../../../../constants/api";
import {useUserSettings} from "../../../../components/context/UserSettingsContext";
import {useAudioOutputControls} from "../../../../components/context/AudioOutputControlsContext";

const ChatHeader: React.FC = () => {
    const socket = useSocket();
    const {userSettings, setUserSettings} = useUserSettings();
    const {controls, setSettings, setIsMuted} = useAudioOutputControls()
    const voiceResponseEnabled = userSettings.voice_response_enabled;

    const handleVoiceResponseSwitchChange = () => {
        if (voiceResponseEnabled) {
            disableVoiceResponse(socket, controls, setSettings)
        } else {
            enableVoiceResponse(socket, controls, setSettings, setIsMuted)
        }
        socket!.emit(WEBSOCKET_EMIT_EVENTS.SWITCH_VOICE_RESPONSE, !voiceResponseEnabled);
        setUserSettings((prev) => {
            return {
                ...prev,
                voice_response_enabled: !prev.voice_response_enabled,
            };
        })
    }

    useEffect(() => {
        if (voiceResponseEnabled) {
            enableVoiceResponse(socket, controls, setSettings, setIsMuted)
        } else {
            disableVoiceResponse(socket, controls, setSettings)
        }
    }, [socket, voiceResponseEnabled])

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'background.body',
            }}
            py={{xs: 2, md: 2}}
            px={{xs: 1, md: 2}}
        >
            <Stack direction="row" spacing={{xs: 1, md: 2}} alignItems="center">
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{
                        display: {xs: 'inline-flex', sm: 'inline-flex', md: 'none'},
                    }}
                    onClick={() => toggleSidebar()}
                >
                    <ArrowBackIosNewRoundedIcon/>
                </IconButton>
                <Avatar size="lg" src={AgentProps.avatar}/>
                <div>
                    <Typography
                        fontWeight="lg"
                        fontSize="lg"
                        component="h2"
                        noWrap
                        endDecorator={
                            AgentProps.online ? (
                                <Chip
                                    variant="outlined"
                                    size="sm"
                                    color="neutral"
                                    sx={{
                                        borderRadius: 'sm',
                                        display: {xs: 'none', sm: 'inline-flex', md: 'inline-flex'},
                                    }}
                                    startDecorator={
                                        <CircleIcon sx={{fontSize: 8}} color="success"/>
                                    }
                                    slotProps={{root: {component: 'span'}}}
                                >
                                    Online
                                </Chip>
                            ) : undefined
                        }
                    >
                        {AgentProps.name}
                    </Typography>
                    <Typography level="body-sm">{AgentProps.username}</Typography>
                </div>
            </Stack>

            <Stack spacing={1} direction="row" alignItems="center">
                <Typography
                    component="label"
                    fontSize="sm"
                    endDecorator={
                        <Switch
                            checked={voiceResponseEnabled}
                            onChange={(_) => handleVoiceResponseSwitchChange()}
                            color={voiceResponseEnabled ? 'primary' : 'neutral'}
                            variant={voiceResponseEnabled ? 'solid' : 'outlined'}
                        />
                    }
                >
                    Voice response
                </Typography>
                {/*<IconButton size="sm" variant="plain" color="neutral">*/}
                {/*    <MoreVertRoundedIcon />*/}
                {/*</IconButton>*/}
            </Stack>
        </Stack>
    );
}

export default ChatHeader;