import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {NullableSubscription, Subscription} from "../../app/payment/types";
import {useSocket} from "./SocketContext";
import {WEBSOCKET_RECEIVE_EVENTS} from "../../../constants/api";
import {SubscriptionsResponse} from "../../../api/responseTypes";
import {isBeforeOrEquals} from "../../../util/utils";


const SubscriptionContext = createContext({
    activeSubscription: null as NullableSubscription,
    futureSubscriptions: [] as Subscription[],
    isSubscriptionLoaded: false,
    isValidSubscription: false
});

export const SubscriptionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const socket = useSocket();
    const [isSubscriptionLoaded, setIsSubscriptionLoaded] = useState<boolean>(false);
    const [activeSubscription, setActiveSubscription] = useState<Subscription | null>(null);
    const [futureSubscriptions, setFutureSubscriptions] = useState<Subscription[]>([]);

    const parseSubscriptionDates = (subscription: any) => {
        if (subscription) {
            return {
                ...subscription,
                start_date: new Date(subscription.start_date),
                end_date: new Date(subscription.end_date)
            };
        } else {
            return null;
        }
    }

    useEffect(() => {
        const subscriptionListener = (response: string) => {
            const parsedJson: SubscriptionsResponse = JSON.parse(response)
            const subscriptionsResponse: SubscriptionsResponse = {
                active_subscription: parseSubscriptionDates(parsedJson.active_subscription),
                future_subscriptions: parsedJson.future_subscriptions.map(parseSubscriptionDates)
            };
            console.log(subscriptionsResponse)
            setIsSubscriptionLoaded(true)

            if (subscriptionsResponse.active_subscription) {
                setActiveSubscription(subscriptionsResponse.active_subscription)
            }
            setFutureSubscriptions(subscriptionsResponse.future_subscriptions)
        };
        if (socket) {
            socket.on(WEBSOCKET_RECEIVE_EVENTS.USER_SUBSCRIPTIONS, subscriptionListener)
        }

        return () => {
            if (socket) {
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.USER_SUBSCRIPTIONS);
            }
        };
    }, [socket]);

    return (
        <SubscriptionContext.Provider value={{
            activeSubscription,
            futureSubscriptions,
            isSubscriptionLoaded,
            isValidSubscription: activeSubscription ? isBeforeOrEquals(new Date(), activeSubscription.end_date): false
        }}>
            {children}
        </SubscriptionContext.Provider>
    );
}

export const useSubscription = () => {
    return useContext(SubscriptionContext);
};