import * as React from "react";
import {useState} from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import {SwipeableOptionsProps} from "../types";
import {Radio, RadioGroup} from "@mui/joy";
import {useSwipeable} from "react-swipeable";

const Screen14: React.FC<SwipeableOptionsProps> = ({optionsPromise}) => {
    const [selectedValue, setSelectedValue] = useState<string>('');
    const isComponentReady = selectedValue !== ''
    const swipeableOptions = optionsPromise(
        isComponentReady,
        (profile) => profile.riskManagement = {
            ...profile.riskManagement,
            stressFrequency: selectedValue
        }
    )

    return (
        <Stack
            {...useSwipeable((swipeableOptions))}
            direction="column"
            justifyContent="space-around"
        >
            <Stack direction="column" gap={7}>
                <Typography level="h3" textAlign="center">
                    How often do you experience significant stress or anxiety related to trading?
                </Typography>
                <RadioGroup
                    name="radio-buttons-group"
                    sx={{
                        gap: 1
                    }}
                    onChange={(event) => setSelectedValue(event.target.value)}
                >
                    <Radio
                        value="Rare"
                        label="Rarely"
                    />
                    <Radio
                        value="Sometimes"
                        label="Occasionally"
                    />
                    <Radio
                        value="Often"
                        label="Frequently"
                    />
                    <Radio
                        value="Almost always"
                        label="Almost always"
                    />
                </RadioGroup>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                gap={2}
            >
                <Button
                    variant="outlined"
                    sx={{
                        width: 80
                    }}
                    onClick={swipeableOptions.onSwipedRight}
                >
                    Back
                </Button>
                <Button
                    sx={{
                        width: 80
                    }}
                    onClick={() => {
                        swipeableOptions.onSwipedLeft();
                    }}
                    disabled={!isComponentReady}
                >
                    Next
                </Button>
            </Stack>
        </Stack>
    );
}

export default Screen14;