import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {useSocket} from "./SocketContext";
import {WEBSOCKET_RECEIVE_EVENTS} from "../../../constants/api";
import {TradingProfile} from "../../app/quiz/types";

const UserTradingProfileContext = createContext({
    userProfile: {},
    setUserProfile: (prev: TradingProfile) => {}
});

export const UserTradingProfileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const socket = useSocket();
    const [userProfile, setUserProfile] = useState<any>({});

    useEffect(() => {
        const userProfileListener = (response: string) => {
            const userProfileResponse = JSON.parse(response)
            console.log(userProfileResponse)
            setUserProfile(userProfileResponse.profile)
        };
        if (socket) {
            socket.on(WEBSOCKET_RECEIVE_EVENTS.USER_PROFILE, userProfileListener)
        }

        return () => {
            if (socket) {
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.USER_PROFILE);
            }
        };
    }, [socket]);

    return (
        <UserTradingProfileContext.Provider value={{ userProfile, setUserProfile }}>
            {children}
        </UserTradingProfileContext.Provider>
    );
}

export const useUserTradingProfile = () => {
    return useContext(UserTradingProfileContext);
};