import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {io, Socket} from 'socket.io-client';
import {COMMON_CONST} from "../../../constants/common";
import {APP_CONST, WEBSOCKET_RECEIVE_EVENTS} from "../../../constants/api";
import {useAuth} from "./AuthContext";

const SocketContext = createContext<Socket | null>(null);

export const SocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const {isAuthenticated, logout} = useAuth();
    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        if (isAuthenticated) {
            const newSocket = io(APP_CONST.URL, {
                transports: ['websocket', 'polling'],
                auth: {
                    token: localStorage.getItem(COMMON_CONST.JWT_TOKEN_ITEM_NAME)
                }
            });

            newSocket.on(WEBSOCKET_RECEIVE_EVENTS.CONNECT_ERROR, (error) => {
                console.error('WebSocket connection error:', error);
                logout(socket)
                setSocket(null)
            });

            setSocket(newSocket);
        }
    }, [isAuthenticated]);

    return (
        <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
};
