import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import * as React from "react";

export const formatCamelCaseToHumanReadable = (input: string) => {
    const words = input.split(/(?=[A-Z])/).map(word => word.toLowerCase());
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    return words.join(' ');
}

export const renderProfileData = (props: any) => (
    <Box sx={{my: 2}}>
        <List>
            {Object.entries(props).map(([key, value]) => (
                <ListItem key={key} sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography level="body-sm"
                                sx={{
                                    color: 'var(--joy-palette-text-primary)',
                                    flex: {xs: 1.2, sm: 1}
                                }}>
                        {formatCamelCaseToHumanReadable(key)}:
                    </Typography>
                    <Typography
                        level="body-sm"
                        sx={{
                            flex: 2,
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 'sm',
                            p: 1,
                            pl: 1.5,
                            // flexGrow: 1,
                            color: 'text.secondary',
                        }}
                    >
                        {typeof value === 'string' || typeof value === 'number' ? value : JSON.stringify(value)}
                    </Typography>
                </ListItem>
            ))}
        </List>
    </Box>
);