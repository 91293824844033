import SvgIcon from "@mui/joy/SvgIcon";
import * as React from "react";

export function BlackFullIcon() {
    return (
        <SvgIcon viewBox="80 0 30 30" sx={{width: "6.5em"}}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.6385 3.66505C8.9852 1.31836 12.168 0 15.4867 0L15.4867 2.95313C12.9512 2.95313 10.5196 3.96036 8.72668 5.75323C6.93381 7.54611 5.92658 9.97777 5.92658 12.5133L2.97345 12.5133C2.97345 9.19455 4.29181 6.01175 6.6385 3.66505ZM28 15.4867C28 13.8435 27.6763 12.2163 27.0475 10.6981C26.4186 9.17992 25.4969 7.80047 24.3349 6.6385C23.173 5.47654 21.7935 4.55482 20.2753 3.92597C18.7572 3.29712 17.13 2.97345 15.4867 2.97345L15.4867 5.92658C16.7422 5.92658 17.9853 6.17386 19.1452 6.65431C20.3051 7.13475 21.359 7.83894 22.2468 8.72668C23.1345 9.61442 23.8387 10.6683 24.3191 11.8282C24.7996 12.9881 25.0469 14.2313 25.0469 15.4867H28ZM0 12.5133C0 14.1565 0.323665 15.7837 0.952516 17.3019C1.58137 18.8201 2.50309 20.1995 3.66505 21.3615C4.82702 22.5235 6.20647 23.4452 7.72465 24.074C9.24283 24.7029 10.87 25.0265 12.5133 25.0265L12.5133 22.0734C11.2578 22.0734 10.0147 21.8261 8.85477 21.3457C7.69488 20.8652 6.64097 20.1611 5.75323 19.2733C4.86549 18.3856 4.1613 17.3317 3.68085 16.1718C3.20041 15.0119 2.95313 13.7687 2.95313 12.5133H0ZM12.5133 28C15.832 28 19.0148 26.6816 21.3615 24.3349C23.7082 21.9882 25.0265 18.8055 25.0265 15.4867L22.0734 15.4867C22.0734 18.0222 21.0662 20.4539 19.2733 22.2468C17.4804 24.0396 15.0488 25.0469 12.5133 25.0469L12.5133 28ZM12.9021 8.0531H13.6283V7.18584H14.3717V8.0531H15.0979V11.3904L17.4288 9.05949L18.9814 10.6121L16.6915 12.9021H19.9469V13.6903H20.9381V14.4336H19.9469V15.0979H16.6505L19.0223 17.4697L17.4697 19.0223L15.0979 16.6505V19.9469H14.3717V20.9381H13.6283V19.9469H12.9021V16.6915L10.5712 19.0223L9.01859 17.4697L11.3904 15.0979H8.0531V14.4336H7.18584V13.6903H8.0531V12.9021H11.3495L9.0595 10.6121L10.6121 9.0595L12.9021 11.3495V8.0531Z"
                  fill="black"/>
            <path
                d="M180.337 15.6522C181.371 16.3041 182 17.2033 182 18.1923C182 20.1705 179.505 21.789 176.403 21.789C173.301 21.789 170.805 20.1705 170.805 18.1923C170.805 18.1474 170.805 18.1024 170.805 18.0575H173.301C173.301 18.1024 173.278 18.1474 173.278 18.1923C173.278 18.9791 174.672 19.6085 176.403 19.6085C178.111 19.6085 179.505 18.9791 179.505 18.1923C179.505 17.428 177.954 17.1133 176.403 16.7986C174.852 16.4839 173.413 16.3715 172.446 15.6522C171.615 15.0453 171.098 14.2585 171.098 13.3818C171.098 11.4936 173.48 9.94254 176.403 9.94254C179.325 9.94254 181.685 11.4936 181.685 13.3818H179.19C179.19 12.685 177.954 12.123 176.403 12.123C174.852 12.123 173.593 12.685 173.593 13.3818C173.593 14.0562 174.874 14.3259 176.403 14.6182C177.954 14.9329 179.325 15.0228 180.337 15.6522Z"
                fill="black"/>
            <path
                d="M168.364 15.6522C169.398 16.3041 170.027 17.2033 170.027 18.1923C170.027 20.1705 167.532 21.789 164.43 21.789C161.328 21.789 158.833 20.1705 158.833 18.1923C158.833 18.1474 158.833 18.1024 158.833 18.0575H161.328C161.328 18.1024 161.305 18.1474 161.305 18.1923C161.305 18.9791 162.699 19.6085 164.43 19.6085C166.138 19.6085 167.532 18.9791 167.532 18.1923C167.532 17.428 165.981 17.1133 164.43 16.7986C162.879 16.4839 161.44 16.3715 160.474 15.6522C159.642 15.0453 159.125 14.2585 159.125 13.3818C159.125 11.4936 161.508 9.94254 164.43 9.94254C167.352 9.94254 169.713 11.4936 169.713 13.3818H167.217C167.217 12.685 165.981 12.123 164.43 12.123C162.879 12.123 161.62 12.685 161.62 13.3818C161.62 14.0562 162.901 14.3259 164.43 14.6182C165.981 14.9329 167.352 15.0228 168.364 15.6522Z"
                fill="black"/>
            <path
                d="M158.127 15.877C158.127 16.1917 158.105 16.5064 158.037 16.7986H147.36C147.832 18.4171 149.473 19.6085 151.428 19.6085C152.687 19.6085 153.789 19.114 154.575 18.3497H157.498C156.441 20.3728 154.126 21.789 151.428 21.789C147.742 21.789 144.752 19.1364 144.752 15.877C144.752 12.5951 147.742 9.94254 151.428 9.94254C154.778 9.94254 157.543 12.1005 158.037 14.9329C158.105 15.2476 158.127 15.5398 158.127 15.877ZM148.191 14.9329H155.497C155.048 13.3144 153.384 12.123 151.428 12.123C149.585 12.123 148.034 13.1795 147.45 14.6406C147.405 14.8205 147.315 14.9778 147.225 15.1127L146.775 15.7646L147.067 15.9669L147.495 15.2925C147.652 15.0677 147.899 14.9329 148.191 14.9329Z"
                fill="black"/>
            <path
                d="M138.458 9.94254C141.38 9.96501 143.741 12.3478 143.741 15.2701V21.4743H141.245V15.2476C141.245 13.5167 139.987 12.123 138.436 12.123C136.12 12.123 134.232 13.8089 134.232 15.877V21.4743H131.759V10.2572H134.232V10.4146C134.232 10.7743 134.165 11.1114 134.03 11.4261L133.468 12.685L133.76 12.8198L134.209 11.8308C134.457 11.2688 134.906 10.8192 135.468 10.5719C136.367 10.1673 137.379 9.94254 138.458 9.94254Z"
                fill="black"/>
            <path
                d="M129.312 19.6085H130.863V21.789H129.312C127.244 21.789 125.58 20.3953 125.58 18.6644V6.52575H128.053V18.6644C128.053 19.1814 128.615 19.6085 129.312 19.6085Z"
                fill="black"/>
            <path
                d="M121.466 10.2572H123.961V21.4742H121.466V21.3169C121.466 20.9797 121.534 20.62 121.691 20.3053L122.23 19.0465L121.938 18.9116L121.489 19.9007C121.241 20.4627 120.814 20.9122 120.252 21.1595C119.331 21.5641 118.319 21.7889 117.24 21.7889C114.318 21.7664 111.98 19.3837 111.98 16.4839V10.2572H114.453V16.4839C114.453 18.2148 115.712 19.6085 117.263 19.6085C119.578 19.6085 121.466 17.9225 121.466 15.8769V10.2572Z"
                fill="black"/>
            <path
                d="M108.791 9.69526C108.791 9.89757 108.701 10.1224 108.544 10.2797L108.05 10.7742L108.297 10.999L108.791 10.5045C108.949 10.3471 109.151 10.2572 109.376 10.2572H111.287V12.123H108.791V21.4742H106.296V12.123H105.689V10.2572H106.296V9.33559C106.296 7.60471 107.982 6.21101 110.028 6.21101H111.601V8.39148H110.028C109.353 8.39148 108.791 8.81858 108.791 9.33559V9.69526Z"
                fill="black"/>
            <path
                d="M104.871 15.877C104.871 16.1917 104.849 16.5064 104.781 16.7986H94.1038C94.5759 18.4171 96.2168 19.6085 98.1725 19.6085C99.4313 19.6085 100.533 19.114 101.32 18.3497H104.242C103.185 20.3728 100.87 21.789 98.1725 21.789C94.486 21.789 91.4962 19.1364 91.4962 15.877C91.4962 12.5951 94.486 9.94254 98.1725 9.94254C101.522 9.94254 104.287 12.1005 104.781 14.9329C104.849 15.2476 104.871 15.5398 104.871 15.877ZM94.9355 14.9329H102.241C101.792 13.3144 100.128 12.123 98.1725 12.123C96.3292 12.123 94.7782 13.1795 94.1937 14.6406C94.1488 14.8205 94.0588 14.9778 93.9689 15.1127L93.5194 15.7646L93.8116 15.9669L94.2387 15.2925C94.396 15.0677 94.6433 14.9329 94.9355 14.9329Z"
                fill="black"/>
            <path
                d="M87.8136 6.52575H90.3087V21.4743H87.8136V15.877C87.8136 13.8089 85.9478 12.123 83.61 12.123C81.2947 12.123 79.4064 13.8089 79.4064 15.877C79.4064 17.9226 80.6653 19.6085 82.2163 19.6085C83.7674 19.6085 85.0262 18.2148 85.0262 16.4839H87.4989C87.4989 19.4062 85.1386 21.789 82.2163 21.789C79.294 21.789 76.9337 19.1365 76.9337 15.877C76.9337 12.5951 79.9235 9.94256 83.61 9.94256C84.6665 9.94256 85.6781 10.1673 86.5548 10.5495C87.1392 10.7968 87.5888 11.2688 87.8585 11.8308L88.2856 12.8199L88.6003 12.685L88.0384 11.4262C87.9035 11.1115 87.8136 10.7743 87.8136 10.4146V6.52575Z"
                fill="black"/>
            <path
                d="M73.2286 10.2572H75.7238V21.4743H73.2286V15.877C73.2286 13.8089 71.3628 12.123 69.025 12.123C66.7097 12.123 64.8214 13.8089 64.8214 15.877C64.8214 17.9226 66.0803 19.6085 67.6313 19.6085C69.1824 19.6085 70.4412 18.2148 70.4412 16.4839H72.9139C72.9139 19.4062 70.5536 21.789 67.6313 21.789C64.709 21.789 62.3488 19.1364 62.3488 15.877C62.3488 12.5951 65.3385 9.94254 69.025 9.94254C70.0815 9.94254 71.0931 10.1673 71.9698 10.5495C72.5542 10.7967 73.0038 11.2688 73.2735 11.8308L73.7006 12.8198L74.0154 12.685L73.4534 11.4261C73.3185 11.1114 73.2286 10.7743 73.2286 10.4146V10.2572Z"
                fill="black"/>
            <path
                d="M56.5145 9.94254C59.4368 9.94254 61.8196 12.3253 61.8196 15.2476H59.3244C59.3244 13.5167 58.0656 12.123 56.5145 12.123C54.1992 12.123 52.311 13.8089 52.311 15.877V21.4743H49.8383V10.2572H52.311V10.4146C52.311 10.7743 52.2435 11.1114 52.1087 11.4261L51.5467 12.685L51.8389 12.8198L52.2885 11.8308C52.5358 11.2688 53.0078 10.7967 53.5698 10.5495C54.469 10.1673 55.458 9.94254 56.5145 9.94254Z"
                fill="black"/>
            <path d="M36 6.52575H49.0603V8.70621H43.7777V21.4743H41.2826V8.70621H36V6.52575Z"
                  fill="black"/>
        </SvgIcon>
    );
}

export function WhiteFullIcon() {
    return (
        <SvgIcon viewBox="80 0 30 30" sx={{width: "6.5em"}}>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M6.6385 3.66505C8.9852 1.31836 12.168 0 15.4867 0L15.4867 2.95313C12.9512 2.95313 10.5196 3.96036 8.72668 5.75323C6.93381 7.54611 5.92658 9.97777 5.92658 12.5133L2.97345 12.5133C2.97345 9.19455 4.29181 6.01175 6.6385 3.66505ZM28 15.4867C28 13.8435 27.6763 12.2163 27.0475 10.6981C26.4186 9.17992 25.4969 7.80047 24.3349 6.6385C23.173 5.47654 21.7935 4.55482 20.2753 3.92597C18.7572 3.29712 17.13 2.97345 15.4867 2.97345L15.4867 5.92658C16.7422 5.92658 17.9853 6.17386 19.1452 6.65431C20.3051 7.13475 21.359 7.83894 22.2468 8.72668C23.1345 9.61442 23.8387 10.6683 24.3191 11.8282C24.7996 12.9881 25.0469 14.2313 25.0469 15.4867H28ZM0 12.5133C0 14.1565 0.323665 15.7837 0.952516 17.3019C1.58137 18.8201 2.50309 20.1995 3.66505 21.3615C4.82702 22.5235 6.20647 23.4452 7.72465 24.074C9.24283 24.7029 10.87 25.0265 12.5133 25.0265L12.5133 22.0734C11.2578 22.0734 10.0147 21.8261 8.85477 21.3457C7.69488 20.8652 6.64097 20.1611 5.75323 19.2733C4.86549 18.3856 4.1613 17.3317 3.68085 16.1718C3.20041 15.0119 2.95313 13.7687 2.95313 12.5133H0ZM12.5133 28C15.832 28 19.0148 26.6816 21.3615 24.3349C23.7082 21.9882 25.0265 18.8055 25.0265 15.4867L22.0734 15.4867C22.0734 18.0222 21.0662 20.4539 19.2733 22.2468C17.4804 24.0396 15.0488 25.0469 12.5133 25.0469L12.5133 28ZM12.9021 8.0531H13.6283V7.18584H14.3717V8.0531H15.0979V11.3904L17.4288 9.05949L18.9814 10.6121L16.6915 12.9021H19.9469V13.6903H20.9381V14.4336H19.9469V15.0979H16.6505L19.0223 17.4697L17.4697 19.0223L15.0979 16.6505V19.9469H14.3717V20.9381H13.6283V19.9469H12.9021V16.6915L10.5712 19.0223L9.01859 17.4697L11.3904 15.0979H8.0531V14.4336H7.18584V13.6903H8.0531V12.9021H11.3495L9.0595 10.6121L10.6121 9.0595L12.9021 11.3495V8.0531Z"
                      fill="white"/>
                <path
                    d="M180.337 15.6522C181.371 16.3041 182 17.2033 182 18.1923C182 20.1705 179.505 21.789 176.403 21.789C173.301 21.789 170.805 20.1705 170.805 18.1923C170.805 18.1474 170.805 18.1024 170.805 18.0575H173.301C173.301 18.1024 173.278 18.1474 173.278 18.1923C173.278 18.9791 174.672 19.6085 176.403 19.6085C178.111 19.6085 179.505 18.9791 179.505 18.1923C179.505 17.428 177.954 17.1133 176.403 16.7986C174.852 16.4839 173.413 16.3715 172.446 15.6522C171.615 15.0453 171.098 14.2585 171.098 13.3818C171.098 11.4936 173.48 9.94254 176.403 9.94254C179.325 9.94254 181.685 11.4936 181.685 13.3818H179.19C179.19 12.685 177.954 12.123 176.403 12.123C174.852 12.123 173.593 12.685 173.593 13.3818C173.593 14.0562 174.874 14.3259 176.403 14.6182C177.954 14.9329 179.325 15.0228 180.337 15.6522Z"
                    fill="white"/>
                <path
                    d="M168.364 15.6522C169.398 16.3041 170.027 17.2033 170.027 18.1923C170.027 20.1705 167.532 21.789 164.43 21.789C161.328 21.789 158.833 20.1705 158.833 18.1923C158.833 18.1474 158.833 18.1024 158.833 18.0575H161.328C161.328 18.1024 161.305 18.1474 161.305 18.1923C161.305 18.9791 162.699 19.6085 164.43 19.6085C166.138 19.6085 167.532 18.9791 167.532 18.1923C167.532 17.428 165.981 17.1133 164.43 16.7986C162.879 16.4839 161.44 16.3715 160.474 15.6522C159.642 15.0453 159.125 14.2585 159.125 13.3818C159.125 11.4936 161.508 9.94254 164.43 9.94254C167.352 9.94254 169.713 11.4936 169.713 13.3818H167.217C167.217 12.685 165.981 12.123 164.43 12.123C162.879 12.123 161.62 12.685 161.62 13.3818C161.62 14.0562 162.901 14.3259 164.43 14.6182C165.981 14.9329 167.352 15.0228 168.364 15.6522Z"
                    fill="white"/>
                <path
                    d="M158.127 15.877C158.127 16.1917 158.105 16.5064 158.037 16.7986H147.36C147.832 18.4171 149.473 19.6085 151.428 19.6085C152.687 19.6085 153.789 19.114 154.575 18.3497H157.498C156.441 20.3728 154.126 21.789 151.428 21.789C147.742 21.789 144.752 19.1364 144.752 15.877C144.752 12.5951 147.742 9.94254 151.428 9.94254C154.778 9.94254 157.543 12.1005 158.037 14.9329C158.105 15.2476 158.127 15.5398 158.127 15.877ZM148.191 14.9329H155.497C155.048 13.3144 153.384 12.123 151.428 12.123C149.585 12.123 148.034 13.1795 147.45 14.6406C147.405 14.8205 147.315 14.9778 147.225 15.1127L146.775 15.7646L147.067 15.9669L147.495 15.2925C147.652 15.0677 147.899 14.9329 148.191 14.9329Z"
                    fill="white"/>
                <path
                    d="M138.458 9.94254C141.38 9.96501 143.741 12.3478 143.741 15.2701V21.4743H141.245V15.2476C141.245 13.5167 139.987 12.123 138.436 12.123C136.12 12.123 134.232 13.8089 134.232 15.877V21.4743H131.759V10.2572H134.232V10.4146C134.232 10.7743 134.165 11.1114 134.03 11.4261L133.468 12.685L133.76 12.8198L134.209 11.8308C134.457 11.2688 134.906 10.8192 135.468 10.5719C136.367 10.1673 137.379 9.94254 138.458 9.94254Z"
                    fill="white"/>
                <path
                    d="M129.312 19.6085H130.863V21.789H129.312C127.244 21.789 125.58 20.3953 125.58 18.6644V6.52575H128.053V18.6644C128.053 19.1814 128.615 19.6085 129.312 19.6085Z"
                    fill="white"/>
                <path
                    d="M121.466 10.2572H123.961V21.4742H121.466V21.3169C121.466 20.9797 121.534 20.62 121.691 20.3053L122.23 19.0465L121.938 18.9116L121.489 19.9007C121.241 20.4627 120.814 20.9122 120.252 21.1595C119.331 21.5641 118.319 21.7889 117.24 21.7889C114.318 21.7664 111.98 19.3837 111.98 16.4839V10.2572H114.453V16.4839C114.453 18.2148 115.712 19.6085 117.263 19.6085C119.578 19.6085 121.466 17.9225 121.466 15.8769V10.2572Z"
                    fill="white"/>
                <path
                    d="M108.791 9.69526C108.791 9.89757 108.701 10.1224 108.544 10.2797L108.05 10.7742L108.297 10.999L108.791 10.5045C108.949 10.3471 109.151 10.2572 109.376 10.2572H111.287V12.123H108.791V21.4742H106.296V12.123H105.689V10.2572H106.296V9.33559C106.296 7.60471 107.982 6.21101 110.028 6.21101H111.601V8.39148H110.028C109.353 8.39148 108.791 8.81858 108.791 9.33559V9.69526Z"
                    fill="white"/>
                <path
                    d="M104.871 15.877C104.871 16.1917 104.849 16.5064 104.781 16.7986H94.1038C94.5759 18.4171 96.2168 19.6085 98.1725 19.6085C99.4313 19.6085 100.533 19.114 101.32 18.3497H104.242C103.185 20.3728 100.87 21.789 98.1725 21.789C94.486 21.789 91.4962 19.1364 91.4962 15.877C91.4962 12.5951 94.486 9.94254 98.1725 9.94254C101.522 9.94254 104.287 12.1005 104.781 14.9329C104.849 15.2476 104.871 15.5398 104.871 15.877ZM94.9355 14.9329H102.241C101.792 13.3144 100.128 12.123 98.1725 12.123C96.3292 12.123 94.7782 13.1795 94.1937 14.6406C94.1488 14.8205 94.0588 14.9778 93.9689 15.1127L93.5194 15.7646L93.8116 15.9669L94.2387 15.2925C94.396 15.0677 94.6433 14.9329 94.9355 14.9329Z"
                    fill="white"/>
                <path
                    d="M87.8136 6.52575H90.3087V21.4743H87.8136V15.877C87.8136 13.8089 85.9478 12.123 83.61 12.123C81.2947 12.123 79.4064 13.8089 79.4064 15.877C79.4064 17.9226 80.6653 19.6085 82.2163 19.6085C83.7674 19.6085 85.0262 18.2148 85.0262 16.4839H87.4989C87.4989 19.4062 85.1386 21.789 82.2163 21.789C79.294 21.789 76.9337 19.1365 76.9337 15.877C76.9337 12.5951 79.9235 9.94256 83.61 9.94256C84.6665 9.94256 85.6781 10.1673 86.5548 10.5495C87.1392 10.7968 87.5888 11.2688 87.8585 11.8308L88.2856 12.8199L88.6003 12.685L88.0384 11.4262C87.9035 11.1115 87.8136 10.7743 87.8136 10.4146V6.52575Z"
                    fill="white"/>
                <path
                    d="M73.2286 10.2572H75.7237V21.4743H73.2286V15.877C73.2286 13.8089 71.3628 12.123 69.025 12.123C66.7097 12.123 64.8214 13.8089 64.8214 15.877C64.8214 17.9226 66.0803 19.6085 67.6313 19.6085C69.1824 19.6085 70.4412 18.2148 70.4412 16.4839H72.9139C72.9139 19.4062 70.5536 21.789 67.6313 21.789C64.709 21.789 62.3487 19.1364 62.3487 15.877C62.3487 12.5951 65.3385 9.94254 69.025 9.94254C70.0815 9.94254 71.0931 10.1673 71.9698 10.5495C72.5542 10.7967 73.0038 11.2688 73.2735 11.8308L73.7006 12.8198L74.0153 12.685L73.4534 11.4261C73.3185 11.1114 73.2286 10.7743 73.2286 10.4146V10.2572Z"
                    fill="white"/>
                <path
                    d="M56.5145 9.94254C59.4368 9.94254 61.8196 12.3253 61.8196 15.2476H59.3244C59.3244 13.5167 58.0656 12.123 56.5145 12.123C54.1992 12.123 52.311 13.8089 52.311 15.877V21.4743H49.8383V10.2572H52.311V10.4146C52.311 10.7743 52.2435 11.1114 52.1087 11.4261L51.5467 12.685L51.8389 12.8198L52.2885 11.8308C52.5358 11.2688 53.0078 10.7967 53.5698 10.5495C54.469 10.1673 55.458 9.94254 56.5145 9.94254Z"
                    fill="white"/>
                <path d="M36 6.52575H49.0603V8.70621H43.7777V21.4743H41.2826V8.70621H36V6.52575Z" fill="white"/>
        </SvgIcon>
    );
}

export function BlackSmallIcon() {
    return (
        <SvgIcon viewBox="0 0 70 70">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.5963 9.16263C22.463 3.2959 30.42 0 38.7168 0V7.38283C32.378 7.38283 26.2989 9.9009 21.8167 14.3831C17.3345 18.8653 14.8165 24.9444 14.8165 31.2832L7.43363 31.2832C7.43363 22.9864 10.7295 15.0294 16.5963 9.16263ZM70 38.7168C70 34.6086 69.1908 30.5407 67.6187 26.7453C66.0466 22.9498 63.7423 19.5012 60.8374 16.5963C57.9324 13.6913 54.4838 11.387 50.6884 9.81492C46.8929 8.24279 42.825 7.43363 38.7168 7.43363L38.7168 14.8165C41.8554 14.8165 44.9634 15.4347 47.8631 16.6358C50.7628 17.8369 53.3976 19.5974 55.6169 21.8167C57.8363 24.0361 59.5967 26.6708 60.7979 29.5705C61.999 32.4703 62.6172 35.5782 62.6172 38.7168H70ZM0 31.2832C0 35.3913 0.809164 39.4593 2.38129 43.2547C3.95342 47.0502 6.25772 50.4988 9.16263 53.4037C12.0675 56.3086 15.5162 58.6129 19.3116 60.1851C23.1071 61.7572 27.175 62.5664 31.2832 62.5664L31.2832 55.1835C28.1445 55.1835 25.0366 54.5653 22.1369 53.3642C19.2372 52.1631 16.6024 50.4026 14.3831 48.1833C12.1637 45.9639 10.4032 43.3292 9.20214 40.4295C8.00103 37.5297 7.38283 34.4218 7.38283 31.2832H0ZM31.2832 70C39.58 70 47.537 66.7041 53.4037 60.8374C59.2705 54.9706 62.5664 47.0136 62.5664 38.7168L55.1835 38.7168C55.1835 45.0556 52.6655 51.1347 48.1833 55.6169C43.7011 60.0991 37.622 62.6172 31.2832 62.6172L31.2832 70ZM32.2553 20.1327H34.0708V17.9646H35.9292V20.1327H37.7447V28.476L43.5719 22.6487L47.4536 26.5304L41.7287 32.2553H49.8673V34.2257H52.3451V36.0841H49.8673V37.7447H41.6263L47.5558 43.6742L43.6742 47.5558L37.7447 41.6264V49.8672H35.9292V52.3451H34.0708V49.8672H32.2553V41.7286L26.4281 47.5558L22.5465 43.6742L28.476 37.7447H20.1327V36.0841H17.9646V34.2257H20.1327V32.2553H28.3736L22.6487 26.5304L26.5304 22.6487L32.2553 28.3737V20.1327Z"
                  fill="black"/>
        </SvgIcon>
    );
}

export function WhiteSmallIcon() {
    return (
        <SvgIcon viewBox="0 0 70 70">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.5963 9.16263C22.463 3.2959 30.42 0 38.7168 0V7.38283C32.378 7.38283 26.2989 9.9009 21.8167 14.3831C17.3345 18.8653 14.8165 24.9444 14.8165 31.2832L7.43363 31.2832C7.43363 22.9864 10.7295 15.0294 16.5963 9.16263ZM70 38.7168C70 34.6086 69.1908 30.5407 67.6187 26.7453C66.0466 22.9498 63.7423 19.5012 60.8374 16.5963C57.9324 13.6913 54.4838 11.387 50.6884 9.81492C46.8929 8.24279 42.825 7.43363 38.7168 7.43363L38.7168 14.8165C41.8554 14.8165 44.9634 15.4347 47.8631 16.6358C50.7628 17.8369 53.3976 19.5974 55.6169 21.8167C57.8363 24.0361 59.5967 26.6708 60.7979 29.5705C61.999 32.4703 62.6172 35.5782 62.6172 38.7168H70ZM0 31.2832C0 35.3913 0.809164 39.4593 2.38129 43.2547C3.95342 47.0502 6.25772 50.4988 9.16263 53.4037C12.0675 56.3086 15.5162 58.6129 19.3116 60.1851C23.1071 61.7572 27.175 62.5664 31.2832 62.5664L31.2832 55.1835C28.1445 55.1835 25.0366 54.5653 22.1369 53.3642C19.2372 52.1631 16.6024 50.4026 14.3831 48.1833C12.1637 45.9639 10.4032 43.3292 9.20214 40.4295C8.00103 37.5297 7.38283 34.4218 7.38283 31.2832H0ZM31.2832 70C39.58 70 47.537 66.7041 53.4037 60.8374C59.2705 54.9706 62.5664 47.0136 62.5664 38.7168L55.1835 38.7168C55.1835 45.0556 52.6655 51.1347 48.1833 55.6169C43.7011 60.0991 37.622 62.6172 31.2832 62.6172L31.2832 70ZM32.2553 20.1327H34.0708V17.9646H35.9292V20.1327H37.7447V28.476L43.5719 22.6487L47.4536 26.5304L41.7287 32.2553H49.8673V34.2257H52.3451V36.0841H49.8673V37.7447H41.6263L47.5558 43.6742L43.6742 47.5558L37.7447 41.6264V49.8672H35.9292V52.3451H34.0708V49.8672H32.2553V41.7286L26.4281 47.5558L22.5465 43.6742L28.476 37.7447H20.1327V36.0841H17.9646V34.2257H20.1327V32.2553H28.3736L22.6487 26.5304L26.5304 22.6487L32.2553 28.3737V20.1327Z"
                  fill="white"/>
        </SvgIcon>
    );
}
