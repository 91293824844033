import React, {useEffect} from "react";
import {useSocket} from "../components/context/SocketContext";
import {WEBSOCKET_RECEIVE_EVENTS} from "../../constants/api";
import {useUserSettings} from "../components/context/UserSettingsContext";
import {useSubscription} from "../components/context/SubscriptionContext";
import LoadingFallback from "../components/LoadingFallback";
import AppRouter from "./router/AppRouter";
import Quiz from "./quiz/Quiz";


const App: React.FC = () => {
    const socket = useSocket();
    const {userSettings, isSettingsLoaded} = useUserSettings();
    const {isSubscriptionLoaded} = useSubscription();

    useEffect(() => {
        const registerErrorListener = (error: string) => {
            alert(error)
        }

        if (socket) {
            socket.on(WEBSOCKET_RECEIVE_EVENTS.REGISTER_ERROR, registerErrorListener)
        }

        return () => {
            if (socket) {
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.REGISTER_ERROR);
            }
        };
    }, [socket]);

    if (!isSubscriptionLoaded || !isSettingsLoaded) {
        return <LoadingFallback size="lg"/>;
    }

    return (
        <>
            {userSettings.quiz_finished ? <AppRouter/> : <Quiz/>}
        </>
    );
}

export default App;
