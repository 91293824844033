import * as React from 'react';
import {useState} from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import GoogleIcon from './GoogleIcon';
import axios from "axios";
import {API_CONST} from "../../constants/api";
import Alert from "@mui/joy/Alert";
import ReportIcon from '@mui/icons-material/Report';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {useGoogleLogin} from "@react-oauth/google";
import SocialNetworks from "../components/SocialNetworks";
import LoginHeader from "../components/LoginHeader";
import {useAuth} from "../components/context/AuthContext";

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement;
    password: HTMLInputElement;
    persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const Login: React.FC = () => {
    const {login} = useAuth();
    const [alert, setAlert] = useState('')
    const [isDisabledButton, setIsDisabledButton] = useState(false)

    const getJwtToken = async (code: string) => {
        const response = await fetch(API_CONST.GOOGLE_AUTH_ENDPOINT, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({code: code})
        })
        await response.json().then(value => {
            console.log(value)
            login(value.token, value.quiz_finished)
        });
    }

    const googleLogin = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async (codeResponse) => {
            console.log(codeResponse);
            await getJwtToken(codeResponse.code);
        }
    });

    const onLoginFormSubmit = async (event: React.FormEvent<SignInFormElement>) => {
        event.preventDefault();
        setIsDisabledButton(true)
        const formElements = event.currentTarget.elements;

        try {
            const response = await axios.get(API_CONST.AUTH_ENDPOINT, {
                auth: {
                    username: formElements.email.value,
                    password: formElements.password.value
                }
            });
            login(response.data.token, response.data.quiz_finished)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                let message;
                if (error.response && error.response.status === 401) {
                    message = 'Invalid username or password';
                } else {
                    message = 'Server error';
                }
                setAlert(message)
            } else {
                throw error
            }
        } finally {
            setIsDisabledButton(false)
        }
    };

    return (
        <>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width: {xs: '100%', lg: '50vw'},
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(120px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width: '100%',
                        px: 2,
                    }}
                >
                    <LoginHeader/>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .MuiFormLabel-asterisk`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{mb: 2}}>
                            <Stack gap={1}>
                                <Typography component="h1" level="h3">
                                    Sign in
                                </Typography>
                                <Typography level="body-sm">
                                    New user?
                                </Typography>
                            </Stack>
                            <Button
                                variant="soft"
                                color="neutral"
                                fullWidth
                                startDecorator={<GoogleIcon/>}
                                onClick={() => googleLogin()}
                            >
                                Continue with Google
                            </Button>
                        </Stack>
                        <Divider
                            sx={(theme) => ({
                                [theme.getColorSchemeSelector('light')]: {
                                    color: 'text.tertiary',
                                },
                            })}
                        >
                            or
                        </Divider>
                        <Stack gap={4} sx={{mt: 2}}>
                            <form onSubmit={onLoginFormSubmit}
                            >
                                <FormControl required>
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" name="email"/>
                                </FormControl>
                                <FormControl required>
                                    <FormLabel>Password</FormLabel>
                                    <Input type="password" name="password"/>
                                </FormControl>
                                {alert && <Alert
                                    size="sm"
                                    color="danger"
                                    variant="outlined"
                                    startDecorator={<ReportIcon/>}
                                    endDecorator={
                                        <IconButton
                                            variant="plain"
                                            color="danger"
                                            onClick={() => setAlert('')}
                                        >
                                            <CloseRoundedIcon/>
                                        </IconButton>
                                    }
                                >
                                    <Typography level="body-sm" color="danger">
                                        {alert}
                                    </Typography>
                                </Alert>}
                                <Stack gap={4} sx={{mt: 2}}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox size="sm" label="Remember me" name="persistent"/>
                                        <Link level="title-sm" href="#replace-with-a-link">
                                            Forgot your password?
                                        </Link>
                                    </Box>
                                    <Button
                                        disabled={isDisabledButton}
                                        loading={isDisabledButton}
                                        type="submit"
                                        fullWidth>
                                        Sign in
                                    </Button>
                                </Stack>
                            </form>
                        </Stack>
                    </Box>
                    <Box component="footer" sx={{pb: 3}}>
                        <SocialNetworks/>
                        <Typography level="body-xs" textAlign="center" sx={{mt: 1}}>
                            © Tradefulness AI {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    width: '55%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: {xs: 0, lg: '50vw'},
                    transition:
                        'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        'url(login_light_background.png)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            'url(login_dark_background.png)',
                    },
                })}
            />
        </>
    );
}

export default Login;