import * as React from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import {SwipeableOptionsProps, TradingProfile} from "../types";
import {useSwipeable} from "react-swipeable";
import {WEBSOCKET_EMIT_EVENTS} from "../../../../constants/api";
import {useSocket} from "../../../components/context/SocketContext";

const Finish: React.FC<SwipeableOptionsProps> = ({optionsPromise}) => {
    const socket = useSocket();
    const sendProfile = (profile: TradingProfile) => {
        if (socket) {
            // TODO: process failed request
            socket.emit(WEBSOCKET_EMIT_EVENTS.USER_PROFILE, profile);
        }
    };
    const swipeableOptions =  optionsPromise(true, sendProfile)

    return (
        <Stack
            direction="column"
            justifyContent="center"
            gap={3}
            mt='-5em'
            {...useSwipeable((swipeableOptions))}
        >
            <Typography
                component="h3"
                level="h3"
                textAlign="center"
            >
                Thanks, let's get started!
            </Typography>
            <Stack
                direction="row"
                justifyContent="center"
                sx={{
                    pt: 2,
                    gap: 2
                }}
            >
                <Button
                    variant="outlined"
                    size="lg"
                    sx={{
                        width: 120
                    }}
                    onClick={swipeableOptions.onSwipedRight}
                >
                    Back
                </Button>
                <Button
                    size="lg"
                    sx={{
                        width: 120
                    }}
                    onClick={swipeableOptions.onSwipedLeft}
                >
                    Continue
                </Button>
            </Stack>
        </Stack>
    );
}

export default Finish;