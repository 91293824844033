import {Socket} from "socket.io-client";
import {Dispatch, SetStateAction} from "react";
import {WEBSOCKET_EMIT_EVENTS, WEBSOCKET_RECEIVE_EVENTS} from "../../../constants/api";
import {ISSTErrorResponse, SSTErrorType, TranscribeSpeechResponse} from "../../../api/responseTypes";
import {AudioInputControls} from "../../types";

export const startVoiceRecording = async (socket: Socket | null,
                                          controls: AudioInputControls,
                                          onChangeMessage: Dispatch<SetStateAction<string>>) => {
    if (!socket) {
        return
    }

    const handleAudioStream = async (stream: MediaStream) => {
        const audioContext = new AudioContext({
            latencyHint: 'interactive',
            sampleRate: 16000
        });
        controls.audioContext = audioContext;

        await audioContext.audioWorklet.addModule('audio-processor.js');
        await audioContext.resume();
        const workletNode = new AudioWorkletNode(audioContext, 'audio-processor');
        controls.workletNode = workletNode;

        const sourceNode = audioContext.createMediaStreamSource(stream);
        controls.sourceNode = sourceNode;

        const channelSplitter = audioContext.createChannelSplitter(sourceNode.channelCount);
        controls.channelSplitterNode = channelSplitter;
        sourceNode.connect(channelSplitter);

        const channelMerger = audioContext.createChannelMerger(1);
        controls.channelMergerNode = channelMerger;
        for (let i = 0; i < sourceNode.channelCount; i++) {
            channelSplitter.connect(channelMerger, i, 0); // Connect channel i of splitter to input 0 of merger
        }

        await audioContext.resume();
        channelMerger.connect(workletNode);

        workletNode.port.onmessage = (event) => {
            socket.emit(WEBSOCKET_EMIT_EVENTS.VOICE_STREAM_DATA, event.data);
        };
    };
    const voice_connection_started = (isStarted: boolean) => {
        if (isStarted) {
            navigator.mediaDevices.getUserMedia({audio: true})
                .then(handleAudioStream)
                .catch((error) => {
                    console.log(error)
                    stopVoiceRecording(socket, controls)
                });
        } else {
            stopVoiceRecording(socket, controls)
        }
    }
    const live_transcribe_listener = (data: string) => {
        const transcriptionResponse: TranscribeSpeechResponse = JSON.parse(data);
        if (transcriptionResponse.speech_final) {
            onChangeMessage((prev: string) => prev + ' ' + transcriptionResponse.transcription.trim());
        }
    }
    const voice_stream_error_listener = (data: string) => {
        const errorResponse: ISSTErrorResponse = JSON.parse(data);
        if (errorResponse.type !== SSTErrorType.RECONNECTION) {
            stopVoiceRecording(socket, controls)
        }
        console.log('Error while streaming audio: ', errorResponse.message)
        alert('Error while streaming audio: ' + data)
    }

    onChangeMessage('');
    if (socket) {
        socket.on(WEBSOCKET_RECEIVE_EVENTS.STT_STARTED, voice_connection_started)
        socket.on(WEBSOCKET_RECEIVE_EVENTS.STT_MESSAGE, live_transcribe_listener);
        socket.on(WEBSOCKET_RECEIVE_EVENTS.STT_ERROR, voice_stream_error_listener);
        socket.emit(WEBSOCKET_EMIT_EVENTS.START_VOICE_STREAM)
    }
};

export const stopVoiceRecording = (socket: Socket | null,
                                   controls: AudioInputControls) => {
    socket?.emit(WEBSOCKET_EMIT_EVENTS.STOP_VOICE_STREAM)
    socket?.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.STT_STARTED)
    socket?.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.STT_MESSAGE)
    socket?.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.STT_ERROR)

    controls.sourceNode?.mediaStream
        .getAudioTracks()
        .forEach(track => track.stop());
    controls.workletNode?.disconnect();
    controls.channelSplitterNode?.disconnect();
    controls.channelMergerNode?.disconnect();
    controls.audioContext?.close().then(() => {
        controls.audioContext = null;
        controls.sourceNode = null;
        controls.workletNode = null;
        controls.channelSplitterNode = null;
        controls.channelMergerNode = null;
    });
};