import React from "react";
import {CardMedia} from "@mui/material";
import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import DefaultHeader from "../../components/DefaultHeader";
import Stack from "@mui/joy/Stack";

const Practices: React.FC = () => {
    return (
        <>
            <DefaultHeader title="Practices"/>
            <Stack
                spacing={4}
                sx={{
                    display: 'flex',
                    flex: 1,
                    py: 3,
                    width: '95%',
                    maxWidth: '800px',
                    alignSelf: 'center'
                }}
            >
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Meditation to set up for trading</Typography>
                    </Box>
                    <Divider/>
                    <CardMedia
                        component='iframe'
                        title='test'
                        src='https://www.youtube.com/embed/CWp4EHA2Fns?si=MLAXinkTqt-O0q4n'
                        sx = {{
                            height: '20em',
                        }}
                    />
                </Card>
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Meditation to cope with stress</Typography>
                    </Box>
                    <Divider/>
                    <CardMedia
                        component='iframe'
                        title='test'
                        src='https://www.youtube.com/embed/JtxSmv6EZ1w?si=4ObhUtrCRQZ6eXz2'
                        sx = {{
                            height: '20em',
                        }}
                    />
                </Card>
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Meditation to return to daily life</Typography>
                    </Box>
                    <Divider/>
                    <CardMedia
                        component='iframe'
                        title='test'
                        src='https://www.youtube.com/embed/rWd0X41-BY0?si=mOqwN0Ti3q_xzdyo'
                        sx = {{
                            height: '20em',
                        }}
                    />
                </Card>
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Meditation for following a trading strategy</Typography>
                    </Box>
                    <Divider/>
                    <CardMedia
                        component='iframe'
                        title='test'
                        src='https://www.youtube.com/embed/MEsvbXC-x7Y?si=169QqxqT6lN_0Cfs'
                        sx = {{
                            height: '20em',
                        }}
                    />
                </Card>
            </Stack>
        </>
    );
}

export default Practices;