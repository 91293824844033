import * as React from "react";
import {useState} from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import {Radio, RadioGroup} from "@mui/joy";
import Input from "@mui/joy/Input";
import {SwipeableOptionsProps} from "../types";
import {useSwipeable} from "react-swipeable";

const Screen16: React.FC<SwipeableOptionsProps> = ({optionsPromise}) => {
    const [selectedValue, setSelectedValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const isComponentReady = selectedValue !== '' && (selectedValue !== 'input' ||
        (selectedValue === 'input' && inputValue.trim() !== ''))
    const swipeableOptions = optionsPromise(
        isComponentReady,
        (profile) => profile.personalInsights = {
            ...profile.personalInsights,
            tradingGoal: selectedValue === 'input' ? inputValue : selectedValue
        })

    const handleRadioChange = (event: any) => {
        setSelectedValue(event.target.value);
    };

    return (
        <Stack
            {...useSwipeable((swipeableOptions))}
            direction="column"
            justifyContent="space-around"
        >
            <Stack direction="column" gap={7}>
                <Typography level="h3" textAlign="center">
                    What is your primary goal in trading?
                </Typography>
                <RadioGroup
                    name="radio-buttons-group"
                    sx={{
                        gap: 1
                    }}
                    value={selectedValue}
                    onChange={handleRadioChange}
                >
                    <Radio
                        value="Additional income"
                        label="Generate supplementary income"
                    />
                    <Radio
                        value="Main income"
                        label="Making money from trading is the main source of income"
                    />
                    <Radio
                        value="Investing"
                        label="Invest and grow my capital over time"
                    />
                    <Radio
                        value="Emotions"
                        label="Enjoy the thrill and challenge of trading"
                    />
                    <Stack direction="row" mt={1}>
                        <Radio
                            value="input"
                            checked={selectedValue === 'input'}
                        />
                        <Input
                            onChange={(event) => setInputValue(event.target.value)}
                            onClick={() => setSelectedValue('input')}
                            placeholder="Other..."
                            sx={{ ml: 1.5 }}
                        />
                    </Stack>
                </RadioGroup>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                gap={2}
            >
                <Button
                    variant="outlined"
                    sx={{
                        width: 80
                    }}
                    onClick={swipeableOptions.onSwipedRight}
                >
                    Back
                </Button>
                <Button
                    sx={{
                        width: 80
                    }}
                    onClick={() => {
                        swipeableOptions.onSwipedLeft();
                    }}
                    disabled={!isComponentReady}
                >
                    Next
                </Button>
            </Stack>
        </Stack>
    );
}

export default Screen16;