import * as React from 'react';
import {useRef, useState} from 'react';
import Box from "@mui/joy/Box";
import LoginHeader from "../../components/LoginHeader";
import Typography from "@mui/joy/Typography";
import QuizScreens from "./QuizScreens";
import Intro from "./screens/Intro";
import Screen1 from "./screens/Screen1";
import Screen2 from "./screens/Screen2";
import Screen3 from "./screens/Screen3";
import Screen4 from "./screens/Screen4";
import Screen5 from "./screens/Screen5";
import Screen6 from "./screens/Screen6";
import Screen7 from "./screens/Screen7";
import Screen8 from "./screens/Screen8";
import Screen9 from "./screens/Screen9";
import Screen10 from "./screens/Screen10";
import Screen11 from "./screens/Screen11";
import Screen12 from "./screens/Screen12";
import Screen13 from "./screens/Screen13";
import Screen14 from "./screens/Screen14";
import Screen15 from "./screens/Screen15";
import Screen16 from "./screens/Screen16";
import Screen17 from "./screens/Screen17";
import Screen18 from "./screens/Screen18";
import Finish from "./screens/Finish";
import {TradingProfile} from "./types";
import {quizTradingProfile} from "./const";

const Quiz: React.FC = () => {
    const userProfile = useRef<TradingProfile>(quizTradingProfile);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const screens = [
        Intro, Screen1, Screen2, Screen3, Screen4, Screen5, Screen6, Screen7, Screen8, Screen9,
        Screen10, Screen11, Screen12, Screen13, Screen14, Screen15, Screen16, Screen17, Screen18,
        Finish
    ]

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100dvh',
                    width: '100%',
                    px: 2,
                }}
            >
                <LoginHeader/>
                <Typography
                    level="body-sm"
                    textAlign="center"
                    textColor={(currentStep === 0 || currentStep === screens.length - 1) ? "#FFFFFF" : ''}
                >
                    {`${currentStep} / ${screens.length - 2}`}
                </Typography>
                <Box
                    component="main"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        overflow: 'hidden',
                        justifyContent: 'center'
                    }}
                >
                    <QuizScreens
                        screens={screens}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        tradingProfile={userProfile.current}
                    />
                </Box>
                <Box
                    component="footer"
                    paddingBottom={{xs: 2, sm: 3}}
                >
                    <Typography level="body-xs" textAlign="center">
                        © Tradefulness AI {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Box>
        </>
    );
}

export default Quiz;