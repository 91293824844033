import {useEffect, useState} from "react";
import {useSocket} from "../../components/context/SocketContext";
import {WEBSOCKET_EMIT_EVENTS} from "../../../constants/api";
import {SubscriptionType} from "./types";

const useSubscriptionTypes = () => {
    const socket = useSocket();
    const [subscriptionTypes, setSubscriptionTypes] = useState<SubscriptionType[]>([])

    useEffect(() => {
        if (socket) {
            socket.emit(WEBSOCKET_EMIT_EVENTS.SUBSCRIPTION_TYPES, (response: any) => {
                const subscriptionTypesResponse = JSON.parse(response)
                console.log(subscriptionTypesResponse)
                setSubscriptionTypes(subscriptionTypesResponse.types)
            });
        }
        setSubscriptionTypes(subscriptionTypes)
        // eslint-disable-next-line
    }, [socket])

    return { subscriptionTypes }
}

export { useSubscriptionTypes };