import {Box, Card, Chip, Stack, Typography} from '@mui/joy';
import {format} from 'date-fns';
import React, {useState} from "react";
import IconButton from "@mui/joy/IconButton";
import Button from "@mui/joy/Button";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import Divider from "@mui/joy/Divider";
import {useNavigate} from "react-router-dom";
import {useSubscription} from "../../../../components/context/SubscriptionContext";
import {ROUTES} from "../../../../../constants/routes";

const SubscriptionSection: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const {activeSubscription, futureSubscriptions, isValidSubscription} = useSubscription();
    const allSubscriptions = [
        ...(activeSubscription ? [activeSubscription] : []),
        ...futureSubscriptions
    ];
    const navigate = useNavigate();

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'active':
                return isValidSubscription ? 'success' : 'warning';
            case 'future':
                return 'primary';
            default:
                return 'neutral';
        }
    };
    const formatDate = (date: Date) => {
        return format(date, 'MMM dd, yyyy');
    };
    const handleNext = () => {
        setCurrentIndex((prev) =>
            prev < allSubscriptions.length - 1 ? prev + 1 : prev
        );
    };
    const handlePrevious = () => {
        setCurrentIndex((prev) => prev > 0 ? prev - 1 : prev);
    };
    const onChoosePlan = () => {
        navigate(ROUTES.PAYMENT)
    }

    return (
        <Card sx={{flex: 1, width: '100%'}}>
            <Box sx={{mb: 1}}>
                <Typography level="title-md">Subscriptions</Typography>
            </Box>
            <Divider/>

            {allSubscriptions.length > 0 ? (
                <Box>
                    <Card
                        variant="plain"
                        sx={{
                            position: 'relative',
                            overflow: 'hidden',
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <IconButton
                                disabled={currentIndex === 0}
                                onClick={handlePrevious}
                                variant="plain"
                                size="sm"
                            >
                               <KeyboardArrowLeft />
                            </IconButton>

                            <Box sx={{flex: 1}}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        mb: 1,
                                    }}
                                >
                                    <Typography level="title-lg">
                                        {allSubscriptions[currentIndex].type.name}
                                    </Typography>
                                    <Chip
                                        color={getStatusColor(allSubscriptions[currentIndex].status)}
                                        variant="soft"
                                        size="md"
                                    >
                                        {allSubscriptions[currentIndex].status.charAt(0).toUpperCase() +
                                            allSubscriptions[currentIndex].status.slice(1)}
                                    </Chip>
                                </Box>

                                <Typography level="body-md">
                                    {formatDate(allSubscriptions[currentIndex].start_date)} - {' '}
                                    {formatDate(allSubscriptions[currentIndex].end_date)}
                                </Typography>
                                {/*<Typography level="body-md" sx={{mt: 1}}>*/}
                                {/*    $5/month*/}
                                {/*</Typography>*/}
                            </Box>

                            <IconButton
                                disabled={currentIndex === allSubscriptions.length - 1}
                                onClick={handleNext}
                                variant="plain"
                                size="sm"
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        </Stack>

                        {allSubscriptions.length > 1 && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 2,
                                    gap: 1
                                }}
                            >
                                {allSubscriptions.map((_, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            bgcolor: index === currentIndex ? 'primary.500' : 'neutral.300',
                                        }}
                                    />
                                ))}
                            </Box>
                        )}
                    </Card>

                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        sx={{mt: 2}}
                    >
                        <Button
                            onClick={onChoosePlan}
                            variant="solid"
                            color="primary"
                        >
                            Add subscription
                        </Button>
                    </Stack>
                </Box>
            ) : (
                <Card variant="plain" color="neutral">
                    <Typography level="body-md" sx={{mb: 2}}>
                        No active subscriptions found.
                    </Typography>
                    <Button
                        onClick={onChoosePlan}
                        variant="solid"
                        color="primary"
                        size="md"
                    >
                        Choose subscription
                    </Button>
                </Card>
            )}
        </Card>
    );
};

export default SubscriptionSection;