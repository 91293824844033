import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {useSocket} from "./SocketContext";
import {WEBSOCKET_RECEIVE_EVENTS} from "../../../constants/api";
import {ChatHistory} from "../../types";

const ChatHistoryContext = createContext({
    chatHistory: {messages: []} as ChatHistory,
    setChatHistory: (prev: (prev: ChatHistory) => ChatHistory) => {}
});

export const ChatHistoryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const socket = useSocket();
    const [chatHistory, setChatHistory] = useState<ChatHistory>({messages: []});

    useEffect(() => {
        const chatHistoryListener = (response: string) => {
            const chatHistoryResponse: ChatHistory = JSON.parse(response)
            console.log(chatHistoryResponse)
            setChatHistory(chatHistoryResponse)
        };
        if (socket) {
            socket.on(WEBSOCKET_RECEIVE_EVENTS.CHAT_HISTORY, chatHistoryListener)
        }

        return () => {
            if (socket) {
                socket.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.CHAT_HISTORY);
            }
        };
    }, [socket]);

    return (
        <ChatHistoryContext.Provider value={{ chatHistory, setChatHistory }}>
            {children}
        </ChatHistoryContext.Provider>
    );
}

export const useChatHistory = () => {
    return useContext(ChatHistoryContext);
};