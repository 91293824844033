import * as React from 'react';
import {ChangeEvent, useRef, useState} from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from "@mui/joy/IconButton";
import Alert from "@mui/joy/Alert";
import ReportIcon from "@mui/icons-material/Report";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Typography from "@mui/joy/Typography";
import {truncateFileName} from "../../../../../util/utils";
import {useTheme} from "@mui/joy";
import {useMediaQuery} from "@mui/material";

interface FileUploadProps {
    file: File | null,
    onFileChange: React.Dispatch<React.SetStateAction<File | null>>
}

const FileUpload: React.FC<FileUploadProps> = ({file, onFileChange}) => {
    const [error, setError] = useState<string | null>(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const allowedTypes = ['text/csv', 'text/plain', 'image/png', 'image/jpeg'];
    const maxFileSize = 10 * 1024 * 1024; // 10MB
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const maxLength = isXs ? 20 : isSm ? 50 : 0;

    const onButtonClick = () => {
        inputFileRef.current?.click();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;
        if (!allowedTypes.includes(file.type)) {
            setError('File type not allowed. Please upload a .csv or .txt file.');
            return;
        }
        if (file.size > maxFileSize) {
            setError('File size exceeds 10MB limit.');
            return;
        }

        setError(null);
        onFileChange(file);
    };

    return (
        <>
            {file && <Alert
                size="sm"
                color="primary"
                variant="outlined"
                endDecorator={
                    <IconButton
                        variant="plain"
                        color="primary"
                        onClick={() => onFileChange(null)}
                    >
                        <CloseRoundedIcon/>
                    </IconButton>
                }
            >
                <Typography level="body-sm" noWrap={true}>
                    {truncateFileName(file.name, maxLength)}
                </Typography>
            </Alert>}
            {!file &&
                <IconButton
                    size="md"
                    variant="plain"
                    color="primary"
                    onClick={onButtonClick}
                >
                    <input
                        type='file'
                        id='file'
                        ref={inputFileRef}
                        style={{display: 'none'}}
                        accept=".txt,.csv,.png,.jpeg"
                        onChange={handleFileChange}
                    />
                    <FileUploadIcon/>
                    {error && <Alert
                        size="sm"
                        color="danger"
                        variant="outlined"
                        startDecorator={<ReportIcon/>}
                        endDecorator={
                            <IconButton
                                variant="plain"
                                color="danger"
                                onClick={() => setError('')}
                            >
                                <CloseRoundedIcon/>
                            </IconButton>
                        }
                    >
                        <Typography level="body-sm" color="danger">
                            {error}
                        </Typography>
                    </Alert>}
                </IconButton>
            }
        </>
    );
}

export default FileUpload;