import * as React from 'react';
import {useColorScheme} from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import {BlackFullIcon, WhiteFullIcon} from "../components/Logo";

const LoginHeader: React.FC = () => {
    const {mode, setMode} = useColorScheme();
    const [mounted, setMounted] = React.useState(false);

    React.useEffect(() => setMounted(true), []);

    return (
        <Box
            component="header"
            sx={{
                pt: 3,
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            {mode === 'light' ? <BlackFullIcon/> : <WhiteFullIcon/>}
            <IconButton
                aria-label="toggle light/dark mode"
                size="sm"
                variant="outlined"
                disabled={!mounted}
                onClick={(event) => {
                    setMode(mode === 'light' ? 'dark' : 'light');
                }}
            >
                {mode === 'light' ? <DarkModeRoundedIcon/> : <LightModeRoundedIcon/>}
            </IconButton>
        </Box>
    );
}

export default LoginHeader;