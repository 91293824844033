import {ITTSErrorResponse} from "../../../api/responseTypes";
import {WEBSOCKET_RECEIVE_EVENTS} from "../../../constants/api";
import {Socket} from "socket.io-client";
import {AudioOutputControls, AudioOutputSettings} from "../../types";

export const enableVoiceResponse = (socket: Socket | null,
                                    controls: AudioOutputControls,
                                    setSettings: ((prev: AudioOutputSettings) => void),
                                    setIsMuted: ((prev: boolean) => void)) => {
    const tts_started_listener = () => {
        setIsMuted(false)
        if (!controls.audioContext) {
            controls.audioContext = new AudioContext();
            if (controls.audioContext.state !== 'suspended') return;
            const b = document.body;
            const events = ['touchstart', 'touchend', 'mousedown', 'keydown'];
            events.forEach(e => b.addEventListener(e, unlock, false));
            const unlock = () => {
                controls.audioContext?.resume().then(clean);
            }
            const clean = () => {
                events.forEach(e => b.removeEventListener(e, unlock));
            }
        }
    }
    const tts_stop_listener = () => {
    }
    const tts_chunk_listener = async (chunk: ArrayBuffer) => {
        controls.pendingChunks.push(chunk);

        if (!controls.isPlaying) {
            await startPlayback(socket, controls, setSettings);
        }
    }
    const tts_error_listener = (error: ITTSErrorResponse) => {
        console.log('tts_error_listener, error:', error)
        stopPlayback(socket, controls, setSettings)
    }
    if (socket) {
        socket.on(WEBSOCKET_RECEIVE_EVENTS.TTS_STARTED, tts_started_listener)
        socket.on(WEBSOCKET_RECEIVE_EVENTS.TTS_CHUNK, tts_chunk_listener)
        socket.on(WEBSOCKET_RECEIVE_EVENTS.TTS_STOP, tts_stop_listener)
        socket.on(WEBSOCKET_RECEIVE_EVENTS.TTS_ERROR, tts_error_listener)
    }
}

export const disableVoiceResponse = (socket: Socket | null,
                                     controls: AudioOutputControls,
                                     setSettings: ((prev: AudioOutputSettings) => void)) => {
    stopPlayback(socket, controls, setSettings)
}

export const muteVoiceResponse = (controls: AudioOutputControls, mute: boolean) => {
    if (controls.gainNode) {
        controls.gainNode.gain.value = mute ? 0 : 1;
    }
}

export const stopVoiceResponse = (controls: AudioOutputControls,
                                  setSettings: ((prev: AudioOutputSettings) => void)) => {
    if (controls.sourceNode) {
        controls.sourceNode.onended = null;
        controls.sourceNode.stop();
    }
    controls.pendingChunks = []
    setSettings({isPlaying: false})
}

const startPlayback = async (socket: Socket | null,
                             controls: AudioOutputControls,
                             setSettings: ((prev: AudioOutputSettings) => void)) => {
    setSettings({isPlaying: true})

    const audioContext = controls.audioContext!;
    const sourceNode = audioContext.createBufferSource();
    controls.sourceNode = sourceNode;
    const gainNode = audioContext.createGain();
    controls.gainNode = gainNode;

    const chunk = controls.pendingChunks.shift()!;
    sourceNode.buffer = await audioContext.decodeAudioData(chunk.slice(0));
    sourceNode.connect(gainNode);
    gainNode.connect(audioContext.destination);

    sourceNode.onended = async () => {
        controls.sourceNode = null;
        if (controls.pendingChunks.length === 0) {
            setSettings({isPlaying: false})
        } else {
            await startPlayback(socket, controls, setSettings);
        }
    };

    sourceNode.start();
}

const stopPlayback = (socket: Socket | null,
                      controls: AudioOutputControls,
                      setSettings: ((prev: AudioOutputSettings) => void)) => {
    setSettings({isPlaying: false})

    if (controls.sourceNode) {
        controls.sourceNode.onended = null;
        controls.sourceNode.stop();
        controls.sourceNode.disconnect();
        controls.sourceNode = null;
    }
    controls.pendingChunks = []
    if (controls.audioContext?.state !== 'closed') {
        controls.audioContext?.close()
    }
    controls.audioContext = null

    socket?.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.TTS_STARTED);
    socket?.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.TTS_CHUNK);
    socket?.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.TTS_STOP);
    socket?.removeAllListeners(WEBSOCKET_RECEIVE_EVENTS.TTS_ERROR);
}