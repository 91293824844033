import * as React from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import Stack from "@mui/joy/Stack";
import IconButton from "@mui/joy/IconButton";


const SocialNetworks: React.FC = () => {

    return (
        <Stack flexDirection="row" justifyContent="center">
            <IconButton
                size="sm"
                color="neutral"
                onClick={() => window.location.assign('https://www.instagram.com/tradefulness?igsh=a3FxcTY5azUxNjNl&utm_source=qr')}
            >
                <InstagramIcon/>
            </IconButton>
            <IconButton
                size="sm"
                color="neutral"
                onClick={() => window.location.assign('https://www.linkedin.com/company/tradefulness/')}
            >
                <LinkedInIcon/>
            </IconButton>
            <IconButton
                size="sm"
                color="neutral"
                onClick={() => window.location.assign('https://www.facebook.com/profile.php?id=61555336212724')}
            >
                <FacebookIcon/>
            </IconButton>
            <IconButton
                size="sm"
                color="neutral"
                onClick={() => window.location.assign('https://youtube.com/@tradefulness?si=48zQGKJrpg3USCxT')}
            >
                <YouTubeIcon/>
            </IconButton>
            <IconButton
                size="sm"
                color="neutral"
                onClick={() => window.location.assign('https://x.com/tradefulnessapp?s=11')}
            >
                <XIcon/>
            </IconButton>
        </Stack>
    );
}

export default SocialNetworks;