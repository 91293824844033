import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import {parseTimestampString, truncateFileName} from "../../../../../util/utils";
import ReactMarkdown from "markdown-to-jsx";
import {Author, IMessage} from "../../../../types";
import {useAudioOutputControls} from "../../../../components/context/AudioOutputControlsContext";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {useTheme} from "@mui/joy";
import {useMediaQuery} from "@mui/material";

interface MessageBubbleProps {
    imessage: IMessage,
    isLast: boolean
}

const MessageBubble: React.FC<MessageBubbleProps> = ({imessage, isLast}) => {
    const {settings, isMuted, setIsMuted} = useAudioOutputControls()
    const {message, author, timestamp, attachment} = {...imessage}
    const isUserMessage = author === Author.USER;
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const maxLength = isXs ? 30 : isSm ? 60 : 0;

    // TODO: this state is lost after component unmount, so maybe need to move to IMessage
    const [isHovered, setIsHovered] = React.useState<boolean>(false);
    const [isLiked, setIsLiked] = React.useState<boolean>(false);
    const [isCelebrated, setIsCelebrated] = React.useState<boolean>(false);

    return (
        <Box
            sx={{
                maxWidth: {xs: '90%', sm: '75%', md: '60%'},
                minWidth: 'auto'
            }}
        >
            <Stack
                direction="row"
                flexDirection={isUserMessage ? 'row-reverse' : 'row'}
                justifyContent="space-between"
                alignItems="end"
                sx={{mb: 0.25}}
            >
                <Typography level="body-xs">{parseTimestampString(timestamp)}</Typography>
                {settings.isPlaying && isLast &&
                    <IconButton
                        variant="plain"
                        color="primary"
                        size="sm"
                        sx={{
                            transform: 'translate(0%, 20%)',
                        }}
                        onClick={() => setIsMuted(!isMuted)}
                    >
                        {!isMuted && <VolumeUpIcon/>}
                        {isMuted && <VolumeOffIcon/>}
                    </IconButton>
                }
            </Stack>
            <Box
                sx={{position: 'relative'}}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Sheet
                    color={isUserMessage ? 'primary' : 'neutral'}
                    variant={isUserMessage ? 'solid' : 'soft'}
                    sx={{
                        mt: 1,
                        p: 1.25,
                        borderRadius: 'lg',
                        borderTopRightRadius: isUserMessage ? 0 : 'lg',
                        borderTopLeftRadius: isUserMessage ? 'lg' : 0,
                        backgroundColor: isUserMessage
                            ? 'var(--joy-palette-primary-solidBg)'
                            : 'background.body'
                    }}
                >
                    <Typography
                        level="body-sm"
                        sx={{
                            color: isUserMessage
                                ? 'var(--joy-palette-common-white)'
                                : 'var(--joy-palette-text-primary)',
                        }}
                        component={'span'}
                    >
                        <ReactMarkdown>{message}</ReactMarkdown>
                    </Typography>
                    {attachment &&
                        <Stack
                            direction="row"
                            spacing={1.5}
                            alignItems="center"
                            pt='5px'
                        >
                            <Avatar color="neutral" size="lg">
                                <InsertDriveFileRoundedIcon/>
                            </Avatar>
                            <div>
                                <Typography
                                    fontSize="sm"
                                    sx={{
                                        color: isUserMessage
                                            ? 'var(--joy-palette-common-white)'
                                            : 'var(--joy-palette-text-primary)'
                                    }}
                                >
                                    {truncateFileName(attachment.name, maxLength)}
                                </Typography>
                                <Typography
                                    level="body-sm"
                                    sx={{
                                        color: isUserMessage
                                            ? 'var(--joy-palette-common-white)'
                                            : 'var(--joy-palette-text-primary)',
                                    }}
                                >
                                    {attachment.size}
                                </Typography>
                            </div>
                        </Stack>
                    }
                </Sheet>
                {(isHovered || isLiked || isCelebrated) && (
                    <Stack
                        direction="row"
                        justifyContent={isUserMessage ? 'flex-end' : 'flex-start'}
                        spacing={0.5}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            p: 1.5,
                            ...(isUserMessage
                                ? {
                                    left: 0,
                                    transform: 'translate(-100%, -50%)',
                                }
                                : {
                                    right: 0,
                                    transform: 'translate(100%, -50%)',
                                }),
                        }}
                    >
                        <IconButton
                            variant={isLiked ? 'soft' : 'plain'}
                            color={isLiked ? 'danger' : 'neutral'}
                            size="sm"
                            onClick={() => setIsLiked((prevState) => !prevState)}
                        >
                            {isLiked ? '❤️' : <FavoriteBorderIcon/>}
                        </IconButton>
                        <IconButton
                            variant={isCelebrated ? 'soft' : 'plain'}
                            color={isCelebrated ? 'warning' : 'neutral'}
                            size="sm"
                            onClick={() => setIsCelebrated((prevState) => !prevState)}
                        >
                            {isCelebrated ? '🎉' : <CelebrationOutlinedIcon/>}
                        </IconButton>
                    </Stack>
                )}
            </Box>
        </Box>
    );
}

export default MessageBubble;