import * as React from 'react';
import ChatHeader from "./components/header/ChatHeader";
import ChatBody from "./components/body/ChatBody";
import ChatInput from "./components/input/ChatInput";

const Chat: React.FC = () => {
    return (
        <>
            <ChatHeader />
            <ChatBody />
            <ChatInput />
        </>
    );
}

export default Chat;