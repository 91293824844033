import React from 'react';
import {CircularProgress, Stack, Typography} from '@mui/joy';

interface LoadingFallbackProps {
    message?: string;
    size?: 'sm' | 'md' | 'lg';
}

const LoadingFallback: React.FC<LoadingFallbackProps> = ({
                                                             message = 'Loading...',
                                                             size = 'md'
                                                         }) => {
    return (
        <Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{minHeight: 200}}
        >
            <CircularProgress size={size}/>
            {message && (
                <Typography level="body-sm" color="neutral">
                    {message}
                </Typography>
            )}
        </Stack>
    );
};

export default LoadingFallback;