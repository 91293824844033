import {TradingProfile} from "./types";

export const quizTradingProfile: TradingProfile = {
    personalInsights: {
        resultAssessment: "",
        timeForAnalysisADay: "",
        tradingGoal: ""
    },
    riskManagement: {
        maxDrawdown: "",
        riskLimit: "",
        stressFrequency: "",
        stressTolerance: "",
        overconfidenceTolerance: "",
        discipline: "",
    },
    tradingExperience: {
        assets: "",
        experience: "",
        fundamentals: "",
        selfAssessment: ""
    },
    tradingStyle: {
        graphTimeFrame: "",
        planAgility: "",
        style: "",
        tradesADay: "",
        tradingDaysAWeek: ""
    }
}