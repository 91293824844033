import {AudioOutputControls, AudioOutputSettings} from "../../types";
import React, {createContext, ReactNode, useContext, useEffect, useRef, useState} from 'react';
import {muteVoiceResponse} from "../../app/chat/audio-output-utils";

const AudioOutputControlsContext = createContext({
    controls: {
        isPlaying: false,
        audioContext: null,
        pendingChunks: [],
        sourceNode: null,
        gainNode: null
    } as AudioOutputControls,
    settings: {
        isPlaying: false
    } as AudioOutputSettings,
    setSettings: (prev: AudioOutputSettings) => {},
    isMuted: false,
    setIsMuted: (value: boolean) => {
    }
});

export const AudioOutputControlsProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const controlsRef = useRef<AudioOutputControls>({
        isPlaying: false,
        audioContext: null,
        sourceNode: null,
        pendingChunks: [],
        gainNode: null
    });
    const [settings, setSettings] = useState<AudioOutputSettings>({
        isPlaying: false
    });
    const setSettingsDecorator = (prev: AudioOutputSettings) => {
        setSettings(prev);
        controlsRef.current.isPlaying = prev.isPlaying;
    }
    const [isMuted, setIsMuted] = useState<boolean>(false);

    useEffect(() => {
        muteVoiceResponse(controlsRef.current, isMuted)
    }, [isMuted, settings]);

    return (
        <AudioOutputControlsContext.Provider value={{
            controls: controlsRef.current,
            settings,
            setSettings: setSettingsDecorator,
            isMuted,
            setIsMuted
        }}>
            {children}
        </AudioOutputControlsContext.Provider>
    );
}

export const useAudioOutputControls = () => {
    return useContext(AudioOutputControlsContext);
};