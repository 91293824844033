import React, {createContext, ReactNode, useContext, useState} from "react";
import {COMMON_CONST} from "../../../constants/common";
import {Socket} from "socket.io-client";

const AuthContext = createContext({
    isAuthenticated: false,
    login: (token: string, quiz_finished: boolean) => {},
    logout: (socket: Socket | null) => {}
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
        !!localStorage.getItem(COMMON_CONST.JWT_TOKEN_ITEM_NAME)
    );
    const login = (token: string) => {
        localStorage.setItem(COMMON_CONST.JWT_TOKEN_ITEM_NAME, token);
        setIsAuthenticated(true)
    }
    const logout = (socket: Socket | null) => {
        localStorage.removeItem(COMMON_CONST.JWT_TOKEN_ITEM_NAME);
        socket?.disconnect()
        setIsAuthenticated(false)
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};