
export const APP_CONST = {
    URL: `${process.env.REACT_APP_API_URL}`,
    STRIPE_PUBLIC_KEY: `${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`
}

const API_ENDPOINT = APP_CONST.URL + "/api"

export const API_CONST = {

    AUTH_ENDPOINT: API_ENDPOINT + "/auth/basic",
    GOOGLE_AUTH_ENDPOINT: API_ENDPOINT + "/auth/google"

}

export const WEBSOCKET_EMIT_EVENTS = {

    REGISTER: 'register',
    USER_PROFILE: 'user_profile',
    SUBSCRIPTION_TYPES: 'subscription_types',
    INIT_SUBSCRIPTION_PAYMENT: 'init_subscription_payment',
    SUBSCRIPTION_PAYMENT_SUCCESS: 'subscription_payment_success',
    SUBSCRIPTION_PAYMENT_FAILURE: 'subscription_payment_failure',

    TEXT_MESSAGE: 'text_message',
    CLEAN_CHAT_HISTORY: 'clean_chat_history',
    START_VOICE_STREAM: 'start_voice_stream',
    VOICE_STREAM_DATA: 'voice_stream_data',
    STOP_VOICE_STREAM: 'stop_voice_stream',
    SWITCH_VOICE_RESPONSE: 'switch_voice_response'

}

export const WEBSOCKET_RECEIVE_EVENTS = {

    CONNECT_ERROR: 'connect_error',
    REGISTER_ERROR: 'register_error',

    CHAT_HISTORY: 'chat_history',
    USER_SETTINGS: 'user_settings',
    USER_PROFILE: 'user_profile',
    USER_SUBSCRIPTIONS: 'user_subscriptions',
    TRADING_JOURNAL: 'trading_journal',

    SUBSCRIPTION_PAYMENT_INTENT_CREATED: 'subscription_payment_intent_created',
    SUBSCRIPTION_PAYMENT_COMPLETED: 'subscription_payment_completed',
    SUBSCRIPTION_PAYMENT_FAILED: 'subscription_payment_failed',

    TEXT_STREAM_STARTED: 'text_stream_started',
    TEXT_STREAM_CHUNK: 'text_stream_chunk',
    TEXT_STREAM_STOP: 'text_stream_stop',
    TEXT_STREAM_ERROR: 'text_stream_error',

    STT_STARTED: 'stt_started',
    STT_MESSAGE: 'stt_message',
    STT_ERROR: 'stt_error',

    TTS_STARTED: 'tts_started',
    TTS_CHUNK: 'tts_chunk',
    TTS_STOP: 'tts_stop',
    TTS_ERROR: 'tts_error',

}
